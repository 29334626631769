/**
 * Created by Mauritz Untamala on 27/08/15.
 */
import * as React from 'react';
import {Component} from 'react';
import I18n from '../../components/I18n';
import StatusColumn from '../../components/StatusColumn';
import Button from '../../components/ConfirmButton';
import * as PropTypes from 'prop-types';
import {navigate} from '../../modules/Location';
import {connect} from 'react-redux';
import Customers from '../../modules/Customers';
import {translate} from 'react-i18next';
import Customer from '../../modules/Customer';
import CustomerModel from '../../models/Customer';
import NameColumn from '../../components/NameColumn';
import DisabledColumn from '../../components/DisabledColumn';
import BaseTableView from '../../components/BaseTableView';
import Permission from '../../services/Permission';
import ToggleButton from '../../components/ToggleButton';
import Criteria from '../../models/Criteria';

class ActionsColumn extends Component<any, any> {

  static propTypes = {
    deleteModel: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  navigateToCustomerProjects = (event) => {

    event.preventDefault();
    this.props.navigate('/customer/' + this.props.rowData.id + '/projects');
  };

  deleteModel = () => this.props.deleteModel(this.props.rowData.src);

  getActions = () => {

    const {rowData} = this.props;
    const actions = [];

    if (this.props.rowData.id) {

      actions.push(
        <a key={'projects_' + rowData.id}
           className='btn btn-default'
           href={'/customer/' + rowData.id + '/projects'}
           onClick={this.navigateToCustomerProjects}
           role='button'>
          <I18n i18nKey={'projects'}/>
        </a>
      );

      actions.push(
        <Button key={'remove_' + rowData.id}
                className='btn btn-default'
                onClick={this.deleteModel}>
          <i className='fa fa-trash fa-lg'/>
        </Button>
      );
    }

    return actions;
  };

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>;
  }
}

const mapStateToProps = ({customers, customer, authenticatedUser}, _ownProps) => {

  const readOnly = !authenticatedUser.hasPermission(Permission.WriteCustomer);

  const criteria = Criteria.getCriteriaWithoutInitialDateRage(_ownProps.location);

  return {
    models: customers,
    model: customer,
    criteria,
    readOnly
  };
};

const mapActionsToProps = {
  updateCriteria: Customers.updateCriteria,
  getModels: Customers.getModels,
  saveModel: Customer.saveModelDebounced,
  deleteModel: Customer.deleteModel,
  navigate
};

@translate(['common'], {wait: true})
class CustomersView extends BaseTableView {

  constructor(props) {
    super(props, 'customers');
  }

  getColumnMetadata() {

    const {t, readOnly} = this.props;

    const columns = [{
      'columnName': 'name',
      'displayName': t('column.customer'),
      'cssClassName': 'name-column',
      'customComponent': NameColumn
    }, {
      'columnName': 'disabled',
      'displayName': t('column.disabled'),
      'cssClassName': 'disabled-column',
      'customComponent': DisabledColumn,
      'filterValue': value => t('' + !value),
      'sortValue': value => t('' + !value)
    }] as Array<any>;

    if (!readOnly) {

      columns.push({
          'columnName': 'actions',
          'displayName': t('column.actions'),
          'cssClassName': 'actions-column',
          'customComponent': ActionsColumn,
          'sortable': false
        }, {
          'columnName': 'isSaving',
          'displayName': '',
          'cssClassName': 'status-column',
          'customComponent': StatusColumn,
          'sortable': false
        }
      );
    }

    return columns;
  }

  createEmptyModel() {
    return new CustomerModel({editing: true});
  }

  handleActiveToggle = () => {

    const {criteria} = this.props;

    this.onCriteriaChange(criteria.set('showDisabled', !criteria.showDisabled));
  }

  getTopContent() {

    const {showDisabled} = this.props.criteria;

    return <ToggleButton
      checked={!showDisabled}
      label={this.props.t('showActivesLabel')}
      onChange={this.handleActiveToggle}/>;
  }
}

export default connect(mapStateToProps, mapActionsToProps)(CustomersView);
