import i18n from 'i18next';
import moment from 'moment-es6';
import * as _ from 'lodash';

const XHR = require('i18next-xhr-backend');
const LanguageDetector = require('i18next-browser-languagedetector');
const resources = require('../locales/index.js');

export function init() {
  i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        whitelist: ['en'],

        // have a common namespace used around the full app
        ns: ['common'],
        defaultNS: 'common',

        debug: true,

        resources: _.cloneDeep(resources),

        interpolation: {
          escapeValue: false, // not needed for react!!
          formatSeparator: ',',
          prefix: '__',
          suffix: '__',
          format: (value, format) => {

            if (format === 'uppercase') {
              return value.toUpperCase();
            }

            return value;
          }
        }
      },
      () => {
        moment.locale(i18n.language);
      }
    );
}

init();

export default i18n;
