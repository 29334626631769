import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import I18n from './I18n';
import Button from './ConfirmButton';

export default class ProjectActionsColumn extends Component<any, any> {

  static propTypes = {
    deleteModel: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
  }

  navigateToProject = (event) => {

    event.preventDefault();
    this.props.navigate(this.getProjectLink());
  };

  removeModel = () => this.props.deleteModel(this.props.rowData.src);

  getProjectLink = () => '/project/' + this.props.rowData.id;

  getActions = () => {

    const {rowData, readOnly} = this.props;
    const actions = [];

    if (rowData.id) {

      actions.push(
        <a key={'projects_' + rowData.id}
           className='btn btn-default'
           href={this.getProjectLink()}
           onClick={this.navigateToProject}
           role='button'>
          <I18n i18nKey={'label.project'}/>
        </a>
      );

      if (!readOnly) {
        actions.push(
          <Button key={'remove_' + rowData.id}
                  className='btn btn-default'
                  onClick={this.removeModel}>
            <i className='fa fa-trash fa-lg'/>
          </Button>
        );
      }
    }

    return actions;
  };

  render() {

    return <div className='actions-wrapper'>{this.getActions()}</div>;
  }
}
