import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import Toggle from 'react-toggle';

export default class DefaultToggleColumn extends Component<any, any> {

  static propTypes = {
    saveModel: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    data: PropTypes.bool,
    readOnly: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
  }

  toggleDefault = () => {

    const {saveModel, rowData, data} = this.props;
    saveModel(rowData.src.set('default', !data));
  };

  render() {

    const {rowData, data, readOnly} = this.props;

    return <Toggle
      id={'default_' + rowData.src._id}
      defaultChecked={data}
      onChange={this.toggleDefault}
      // Only allow to modify when have write permission AND the row is not disabled
      disabled={readOnly || rowData.src.disabled}/>;
  }
}
