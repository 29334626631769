/**
 * Created by Mauritz Untamala on 8/09/15.
 */
import * as React from 'react';
import I18n from '../../components/I18n';
import StatusColumn from '../../components/StatusColumn';
import {connect} from 'react-redux';
import Customer from '../../modules/Customer';
import Project from '../../modules/Project';
import {navigate} from '../../modules/Location';
import {translate} from 'react-i18next';
import CustomerProjects from '../../modules/CustomerProjects';
import NameColumn from '../../components/NameColumn';
import DisabledColumn from '../../components/DisabledColumn';
import BaseTableView from '../../components/BaseTableView';
import ProjectModel from '../../models/Project';
import ProjectActionsColumn from '../../components/ProjectActionColumn';
import Permission from '../../services/Permission';

const mapActionCreators = {
  fetchCustomer: Customer.getModel,
  getModels: CustomerProjects.fetchCustomerProjects,
  saveModel: Project.saveModelDebounced,
  deleteModel: Project.deleteModel,
  navigate
};

const mapStateToProps = ({
                           customer,
                           project,
                           customerProjects,
                           authenticatedUser,
                           tasks
                         }, ownProps) => {

  const readOnly = !authenticatedUser.hasPermission(Permission.WriteCustomer);
  const customerId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined;

  return {
    customerId,
    customer,
    model: project,
    models: customerProjects,
    authenticatedUser,
    tasks,
    readOnly
  };
};

@translate(['common'], {wait: true})
class CustomerProjectsView extends BaseTableView {

  constructor(props) {
    super(props, 'projects');
  }

  componentDidMount() {

    const {customer, customerId, fetchCustomer, getModels} = this.props;

    if (!customer) {

      fetchCustomer(customerId);
    }

    getModels(customerId);
  }

  getColumnMetadata() {

    const {t, readOnly} = this.props;

    const columns = [
      {
        'columnName': 'name',
        'cssClassName': 'name-column',
        'displayName': t('column.name'),
        'customComponent': NameColumn
      },
      {
        'columnName': 'disabled',
        'cssClassName': 'disabled-column',
        'displayName': t('column.disabled'),
        'customComponent': DisabledColumn,
        'filterValue': data => t('' + !data),
        'sortValue': data => t('' + !data)
      }
    ] as Array<any>;

    if (!readOnly) {

      columns.push({
          'columnName': 'actions',
          'displayName': t('column.actions'),
          'cssClassName': 'actions-column',
          'customComponent': ProjectActionsColumn,
          'sortable': false
        },
        {
          'columnName': 'isSaving',
          'displayName': '',
          'cssClassName': 'status-column',
          'customComponent': StatusColumn,
          'sortable': false
        });
    }

    return columns;
  }

  getTitle() {
    const customer = this.props.customer;

    return (
      <div className='page-title'>
        <I18n i18nKey='customerProjectsView.title' args={{name: (customer && customer.name)}}/>
      </div>
    );
  }

  createEmptyModel() {
    const {customerId, authenticatedUser, tasks} = this.props;
    const defaultTaskIds = tasks.getDefaultTasks().map(task => task.id);
    return new ProjectModel({
      editing: true,
      customerId,
      userIds: [authenticatedUser.id],
      taskIds: defaultTaskIds
    });
  }
}

export default connect(mapStateToProps, mapActionCreators)(CustomerProjectsView);
