import BaseModel from './BaseModel';

const defaultValues = {
  date: undefined,
  projectId: undefined,
  taskId: undefined,
  hours: undefined,
  description: undefined,
  invoiced: undefined
};

const equalsKeys = ['taskId', 'hours', 'description'];

const constraints = {
  date: {
    presence: true
  },
  taskId: {
    presence: true
  },
  hours: {
    presence: true,
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      lessThanOrEqualTo: 24
    }
  }
};

export default class HourReport extends BaseModel(defaultValues, equalsKeys, constraints)<HourReport> {

  date: string;
  hours: number;

  fromJS(js): HourReport {

    return new HourReport(js);
  }
}
