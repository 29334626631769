import {createAction, handleActions} from 'redux-actions';
import Location from '../models/Location';
import {push} from 'react-router-redux';
import Criteria from '../models/Criteria';
import {stringify} from 'querystring';
import * as _ from 'lodash';
import Config from '../config';

const initialState = new Location();

const locationPrefix = `Location`;

export const PUSH_NEW_STATE = `${locationPrefix}.PUSH_NEW_STATE`;
export const POP_HISTORY_STACK = `${locationPrefix}.POP_HISTORY_STACK`;
export const pushStateAction = createAction(PUSH_NEW_STATE);
export const popHistoryStackAction = createAction(POP_HISTORY_STACK);

export function pushState(state) {

  return dispatch => dispatch(pushStateAction(state));
}

// TODO figure out if silent can be used with router
export function navigate(url, silent = false) {

  return dispatch => {

    if (silent) {
      return window.history.pushState({}, '', url);
    }

    dispatch(pushState(url));

    return dispatch(push(url));
  };
}

export function navigateToBackend(url) {

  return () => {

    return window.location.replace(Config.API_URL + (url.indexOf('/') !== -1 ? url : `/${url}`));
  };
}

export function navigateBack(url, force = false) {

  return (dispatch, getState) => {

    const {location} = getState();

    if (!force && location.checkIfStackSizeHasPreviousState()) {

      const previousUrl = location.getPreviousState();
      dispatch(popHistoryStackAction(location.popHistoryStack()));
      url = previousUrl;
    }

    return dispatch(navigate(url));
  };
}

export function updateCriteria(location, criteria: Criteria) {

  return (dispatch) => {

    const {pathname} = location;
    const updatedQuery = criteria.getQueryParams();

    const getNewUrl = () => {

      if (_.isEmpty(updatedQuery)) {

        return pathname;

      } else {

        return `${pathname}?${stringify(updatedQuery)}`;
      }
    };

    return dispatch(navigate(getNewUrl()));
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PUSH_NEW_STATE]: (state: Location, {payload}) => state.pushState(payload),
  [POP_HISTORY_STACK]: (state) => state.popHistoryStack()
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
