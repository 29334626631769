import Task from './Task';
import Collection from './ImmutableCollection';

export default class Tasks extends Collection(Task)<Task> {
  getDefaultTasks() {
    return this.list.filter(task => task.default && !task.disabled);
  }

  isTaskDisabled(taskId) {
    const task = this.getModelById(taskId);
    return task ? task.disabled : true;
  }
}
