/**
 * Created by Mauritz Untamala on 05/10/15.
 */
import * as React from 'react';
import {Component, PureComponent} from 'react';
import moment from 'moment-es6';
import * as _ from 'lodash';
import classNames from 'classnames';
import I18n from '../../components/I18n';
import CriteriaModel from '../../models/Criteria';
import RangeDatePicker from '../../components/RangeDatePicker';
import DownloadButton from '../../components/DownloadButton';
import TableComponent from '../../components/TableComponent';
import {Panel} from 'react-bootstrap';
import InlineConfirm from 'react-inline-confirm';
import {PropTypes} from 'prop-types';
import {DATE_API_FORMAT, DATE_SHORT_FORMAT} from '../../config/constants';
import Customers from '../../modules/Customers';
import Projects from '../../modules/Projects';
import Tasks from '../../modules/Tasks';
import Users from '../../modules/Users';
import Reports from '../../modules/Reports';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Permission from '../../services/Permission';
import Select from '../../components/Select';

const DETAILED_FIELDS = ['date', 'description', 'invoiced'];

class Criteria extends PureComponent<any, any> {

  static propTypes = {
    customers: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    criteria: PropTypes.object.isRequired,
    onCriteriaChange: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {open: true};
  }

  onInvoicedChange = (event) => {

    let invoiced;

    if (event.target.checked) {
      invoiced = event.target.value === 'true';
    }

    this.props.onCriteriaChange(this.props.criteria.set('invoiced', invoiced));
  };

  onDetailedChange = (event) => {

    let detailed;

    if (event.target.checked) {
      detailed = event.target.value === 'true';
    }

    this.props.onCriteriaChange(this.props.criteria.set('detailed', detailed));
  };

  onListCriteriaChange = (listName, newItems) => {

    const {criteria} = this.props;
    const list = criteria.get(listName).clear();

    this.props.onCriteriaChange(criteria.set(listName, list.concat(newItems)));
  };

  onRangeChange = (from, to) => {

    return this.props.onCriteriaChange(
      this.props.criteria
        .set('from', from ? moment(from) : undefined)
        .set('to', to ? moment(to) : undefined)
    );
  };

  onSelectedCustomersChange = (selectedCustomers) => this.onListCriteriaChange('customers', selectedCustomers);

  onSelectedProjectsChange = (selectedProjects) => this.onListCriteriaChange('projects', selectedProjects);

  onSelectedTasksChange = (selectedTasks) => this.onListCriteriaChange('tasks', selectedTasks);

  onSelectedUsersChange = (selectedUsers) => this.onListCriteriaChange('users', selectedUsers);

  criteriaEntry = (labelKey, content) => {

    return (
      <div key={labelKey} className='form-group'>
        <label className='col-sm-2 control-label'>
          <I18n i18nKey={labelKey}/>
        </label>

        <div className='col-sm-10'>
          {content}
        </div>
      </div>
    );
  };

  createSelectCriteria = (criteriaName, label, onChange, requiredPermission: Permission = undefined) => {

    if (requiredPermission && !this.props.authenticatedUser.hasPermission(requiredPermission)) {
      return undefined;
    }

    const modelToOption = (model) => {

      const labelValue = _.isFunction(label) ? label(model) : model[label];

      return {
        label: labelValue,
        value: model.id,
        disabled: !!model.disabled
      };
    };

    return <div key={criteriaName + '_group'} className='form-group'>
      <label className='col-sm-2 control-label' htmlFor='criteria'>
        <I18n i18nKey={criteriaName}/>
      </label>

      <div className='col-sm-10'>
        <Select models={this.props[criteriaName].list}
                selectedOptions={this.props.criteria[criteriaName].toArray()}
                modelToOption={modelToOption}
                isMulti={true}
                onChange={onChange}
                isClearable={true}
                placeholder={this.props.t(criteriaName)}/>
      </div>
    </div>;
  };

  toDate = (momentDate) => momentDate ? momentDate.toDate() : undefined;

  createRangeEntry = (criteria) => this.criteriaEntry('dateRange', <RangeDatePicker id='range'
                                                                                    from={this.toDate(criteria.from)}
                                                                                    to={this.toDate(criteria.to)}
                                                                                    onChange={this.onRangeChange}
                                                                                    className='form-control'/>);

  createInvoicedEntry = (criteria) => {

    return this.criteriaEntry('invoiced', <div className='checkbox'>
      <label>
        <input type='checkbox'
               onClick={this.onInvoicedChange}
               checked={criteria.get('invoiced') === true}
               value='true'/> <I18n i18nKey='true'/>
      </label>
      <label>
        <input type='checkbox'
               onClick={this.onInvoicedChange}
               checked={criteria.get('invoiced') === false}
               value='false'/> <I18n i18nKey='false'/>
      </label>
    </div>);
  };

  createDetailedEntry = (criteria) => {

    return this.criteriaEntry('detailed', <div className='checkbox'>
      <label>
        <input type='checkbox'
               onClick={this.onDetailedChange}
               defaultChecked={criteria.get('detailed') === true}
               value='true'/>
      </label>
    </div>);
  };

  projectToLabel = (project) => project.customerName + ' - ' + project.name;

  getCriteriaForm = () => {

    const {criteria} = this.props;

    return (
      <div key='criteria-form' className='form-horizontal'>
        {this.createSelectCriteria('customers', 'name', this.onSelectedCustomersChange, Permission.ReadCustomer)}
        {this.createSelectCriteria('projects', this.projectToLabel, this.onSelectedProjectsChange, Permission.ReadProject)}
        {this.createSelectCriteria('tasks', 'name', this.onSelectedTasksChange, Permission.ReadTask)}
        {this.createSelectCriteria('users', 'displayName', this.onSelectedUsersChange, Permission.ReadUser)}
        {this.createRangeEntry(criteria)}
        {this.createInvoicedEntry(criteria)}
        {this.createDetailedEntry(criteria)}
      </div>
    );
  };

  render() {

    const classes = classNames({
      'criteria': true,
      'input-group': true,
      'panel-group': true,
      'open': this.state.open
    });

    return (
      <div className={classes}>
        <Panel expanded={this.state.open}
               defaultExpanded={this.state.open}
               onToggle={() => this.setState({open: !this.state.open})}>
          <Panel.Heading onClick={() => this.setState({open: !this.state.open})}>
            <span className='criteria-title'>{this.props.t('report.criteria')}</span>
          </Panel.Heading>
          <Panel.Collapse><Panel.Body>{this.getCriteriaForm()}</Panel.Body></Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

class SelectAll extends Component<any, any> {

  static propTypes = {
    onSelectAll: PropTypes.func.isRequired,
    onSelectNone: PropTypes.func.isRequired
  };

  render() {

    return (
      <div className='select-all'>
        <I18n i18nKey='selectAll'/><a onClick={this.props.onSelectAll}><I18n i18nKey='all'/></a>, <a
        onClick={this.props.onSelectNone}><I18n i18nKey='none'/></a>.
      </div>
    );
  }
}

const textValues = ['Invoice', 'Click to confirm', 'Invoicing...'];

class Summary extends Component<any, any> {

  static propTypes = {
    reports: PropTypes.object.isRequired,
    onInvoiceSelected: PropTypes.func.isRequired,
    onMakeFile: PropTypes.func.isRequired,
    setReportsSelected: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  onSelectAll = (event) => {

    event.preventDefault();
    const reportIds = this.props.reports.list.map(r => r.id).toArray();

    this.props.setReportsSelected(reportIds, true);
  };

  onSelectNone = (event) => {

    event.preventDefault();
    const reportIds = this.props.reports.list.map(r => r.id).toArray();

    this.props.setReportsSelected(reportIds, false);
  };

  render() {

    return (
      <div className='summary'>
        <DownloadButton filename='report.csv'
                        type='text/csv;charset=utf8;'
                        contents={this.props.onMakeFile}
                        labelKey='label.downloadReport'/>
        <InlineConfirm className='btn btn-default'
                       textValues={textValues}
                       showTimer={true}
                       onClick={this.props.onInvoiceSelected}
                       isExecuting={this.props.reports.isInvoicing}/>
        <div className='summary-group'>
          <SelectAll onSelectAll={this.onSelectAll} onSelectNone={this.onSelectNone}/>
          <I18n i18nKey='report.totalHours' args={{total: this.props.reports.getTotalHours()}}/>
        </div>
      </div>
    );
  }
}

class SelectColumn extends Component<any, any> {

  constructor(props, context) {
    super(props, context);
  }

  onChange = (event) => this.props.setReportsSelected([this.props.rowData.id], event.target.checked);

  render() {

    return <input type='checkbox' checked={this.props.data} onChange={this.onChange}/>;
  }
}

function BooleanColumn(props) {

  return <I18n i18nKey={'' + !!props.data}/>;
}

function DateColumn(props) {

  return (
    <div>
      <span style={{display: 'none'}}>{props.data.format(DATE_API_FORMAT)}</span>
      {props.data.format(DATE_SHORT_FORMAT)}
    </div>
  );
}

class Results extends PureComponent<any, any> {

  static propTypes = {
    customers: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    criteria: PropTypes.object.isRequired,
    onCriteriaChange: PropTypes.func.isRequired,
    setReportsSelected: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  getColumnMetadata = () => {

    const {t} = this.props;

    let metadata = [
      {
        'columnName': 'selected',
        'displayName': t('column.selected'),
        'cssClassName': 'selected-column',
        'customComponent': SelectColumn
      },
      {
        'columnName': 'customerName',
        'cssClassName': 'customer-column',
        'displayName': t('column.customer'),
        'customComponent': this.createFilterComponent('customer')
      },
      {
        'columnName': 'projectName',
        'cssClassName': 'project-column',
        'displayName': t('column.project'),
        'customComponent': this.createFilterComponent('project')
      },
      {
        'columnName': 'taskName',
        'cssClassName': 'task-column',
        'displayName': t('column.task'),
        'customComponent': this.createFilterComponent('task')
      },
      {
        'columnName': 'userName',
        'cssClassName': 'user-column',
        'displayName': t('column.user'),
        'customComponent': this.createFilterComponent('user')
      },
      {
        'columnName': 'hours',
        'displayName': t('column.hours')
      }
    ] as any;

    if (this.props.criteria.get('detailed')) {
      metadata = metadata.concat([{
        'columnName': 'date',
        'cssClassName': 'date-column',
        'displayName': t('column.date'),
        'customComponent': DateColumn
      }, {
        'columnName': 'description',
        'cssClassName': 'description-column',
        'displayName': t('column.description')
      }, {
        'columnName': 'invoiced',
        'cssClassName': 'invoiced-column',
        'displayName': t('column.invoiced'),
        'customComponent': BooleanColumn
      }]);
    }

    return metadata;
  };

  createFilterComponent = (filter) => {

    const parent = this;

    return (props) => {

      return {
        filter() {
          parent.filterBy(filter, props.rowData);
        },
        render() {

          return <a onClick={this.filter} role='button'>{props.data}</a>;
        }
      };
    };
  };

  filterBy = (filter, report) => {

    const {criteria, onCriteriaChange} = this.props;

    const updateCriteriaList = (listName, id) => onCriteriaChange(criteria.set(listName, criteria.get(listName).clear().push(id)));

    switch (filter) {
      case 'customer':
        updateCriteriaList('customers', report.customerId);
        break;
      case 'project':
        updateCriteriaList('projects', report.projectId);
        break;
      case 'user':
        updateCriteriaList('users', report.userId);
        break;
      case 'task':
        updateCriteriaList('tasks', report.taskId);
        break;
      default:
        console.log('Unknown filter:', filter);
        break;
    }
  };

  getResults = () => this.props.reports.list.map((model) => _.extend(model.toJS(), {src: model})).toArray();

  render() {

    return (
      <TableComponent key='results'
                      data={this.getResults()}
                      rowKey='_id'
                      columns={this.getColumnMetadata()}
                      useFixedHeader={true}
                      customProps={this.props}/>
    );
  }
}

const getCriteria = ({query}) => {

  const hasParams = !!_.find(Object.keys(query), (key) => key !== '');

  if (hasParams) {

    return CriteriaModel.fromQuery(query, true);

  } else {

    return CriteriaModel.getInitialCriteria(true);
  }
};

const mapStateToProps = ({customers, projects, tasks, users, reports, authenticatedUser}, ownProps) => {

  const criteria = getCriteria(ownProps.location);

  return {
    criteria,
    customers,
    projects,
    tasks,
    users,
    reports,
    authenticatedUser
  };
};

const mapActionsToProps = {
  fetchCustomers: Customers.getModels,
  fetchProjects: Projects.getModels,
  fetchProjectTasks: Tasks.fetchProjectTasks,
  fetchUsers: Users.getModels,
  updateCriteria: Reports.updateCriteria,
  invoiceReports: Reports.invoiceReports,
  setReportsSelected: Reports.setReportsSelected
};

@translate(['common'], {wait: true})
class ReportView extends PureComponent<any, any> {

  static propTypes = {
    fetchCustomers: PropTypes.func.isRequired,
    fetchProjects: PropTypes.func.isRequired,
    fetchProjectTasks: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    updateCriteria: PropTypes.func.isRequired,
    invoiceReports: PropTypes.func.isRequired,
    setReportsSelected: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    criteria: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {

    const {
      fetchCustomers,
      fetchProjects,
      fetchProjectTasks,
      fetchUsers,
      updateCriteria,
      location,
      criteria,
      authenticatedUser
    } = this.props;

    if (authenticatedUser.hasPermission(Permission.ReadCustomer)) {

      fetchCustomers();
    }

    if (authenticatedUser.hasPermission(Permission.ReadProject)) {

      fetchProjects();
    }

    if (authenticatedUser.hasPermission(Permission.ReadTask)) {

      fetchProjectTasks();
    }

    if (authenticatedUser.hasPermission(Permission.ReadUser)) {
      fetchUsers();
    }

    updateCriteria(location, criteria);
  }

  onCriteriaChange = (criteria) => this.props.updateCriteria(this.props.location, criteria);

  onInvoiceSelected = () => {

    const {reports, criteria, invoiceReports} = this.props;

    const reportIds = reports.list
      .filter((report) => report.selected)
      .reduce((accu, report) => {

        if (criteria.get('detailed')) {

          accu.push(report.get('id'));

        } else {

          accu = accu.concat(report.get('ids'));
        }

        return accu;
      }, []);

    if (reportIds) {

      invoiceReports(reportIds);
    }
  };

  onMakeFile = () => {

    const {criteria, t, reports} = this.props;

    let columnHeaders = ['customer', 'project', 'task', 'user', 'hours'];
    let columnFields = ['customerName', 'projectName', 'taskName', 'userName', 'hours'];

    if (criteria.get('detailed')) {

      columnHeaders = columnHeaders.concat(DETAILED_FIELDS);
      columnFields = columnFields.concat(DETAILED_FIELDS);
    }

    const header = columnHeaders
      .map(field => t('column.' + field))
      .reduce((accu, column) => accu + column + ';', '');

    const rows = reports.list
      .map((report) => {

        return columnFields
          .map(key => {

            const value = report.get(key);

            return key === 'date' ? moment(value).format(DATE_SHORT_FORMAT) : value;
          })
          .reduce((accu, value) => {

            value = _.isNumber(value) ? value.toString().replace('.', ',') : value;

            return accu + value + ';';
          }, '');
      })
      .reduce((accu, row) => accu + row + '\n', '');

    return header + '\n' + rows;
  };

  render() {

    const {reports, setReportsSelected} = this.props;

    return (
      <div key='report-view-container' className='report-view-container'>
        <div className='page-title'>
          <I18n i18nKey='reportView.title'/>
        </div>
        <Criteria {...this.props} onCriteriaChange={this.onCriteriaChange}/>
        <Summary reports={reports}
                 setReportsSelected={setReportsSelected}
                 onInvoiceSelected={this.onInvoiceSelected}
                 onMakeFile={this.onMakeFile}/>
        <Results {...this.props} onCriteriaChange={this.onCriteriaChange}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportView);
