import Projects from '../models/Projects';
import ModelsModule from './ModelsModule';
import {bind} from '../util';

const initialState = new Projects();

class UserProjectsModule extends ModelsModule {

  constructor() {
    super('user-project', initialState, {path: 'user'});
    bind(this, this.fetchUserProjects);
  }

  fetchUserProjects(userId) {

    return this.getModels(undefined, true, undefined, `${userId}/projects`);
  }
}

export default new UserProjectsModule();
