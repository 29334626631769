import * as React from 'react';
import NavigationLayout from '../../layouts/NavigationLayout';
import HourReportingView from './HourReportingView';
import CustomersView from './CustomersView';
import ProjectsView from './ProjectsView';
import ProjectView from './ProjectView';
import UsersView from './UsersView';
import UserView from './UserView';
import CustomerProjectsView from './CustomerProjectsView';
import ReportView from './ReportView';
import TasksView from './TasksView';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import Authorization from './Authorization';
import Permission from '../../services/Permission';
import {connect} from 'react-redux';

const hourReportPermission = Authorization([Permission.WriteHourReport, Permission.ReadHourReport]);
const customerPermission = Authorization([Permission.WriteCustomer, Permission.ReadCustomer]);
const projectPermission = Authorization([Permission.WriteProject, Permission.ReadProject]);
const userPermission = Authorization([Permission.WriteUser, Permission.ReadUser]);
const userProfilePermission = Authorization([Permission.WriteUserProfile, Permission.ReadUserProfile]);
const reportPermission = Authorization([Permission.WriteReport, Permission.ReadReport]);
const taskPermission = Authorization([Permission.WriteTask]);
const ProfileView = connect(({authenticatedUser}) => ({userId: authenticatedUser.id}))((props) => <UserView {...props}/>);

export default {
  component: AppLayout,
  childRoutes: [
    {
      component: NavigationLayout,
      childRoutes: [
        {path: 'hour-report', component: hourReportPermission(HourReportingView)},
        {path: 'customers', component: customerPermission(CustomersView)},
        {path: 'projects', component: projectPermission(ProjectsView)},
        {path: 'project/:id', component: projectPermission(ProjectView)},
        {path: 'users', component: userPermission(UsersView)},
        {path: 'user/:id', component: userPermission(UserView)},
        {path: 'profile', component: userProfilePermission(ProfileView)},
        {path: 'customer/:id/projects', component: projectPermission(CustomerProjectsView)},
        {path: 'report', component: reportPermission(ReportView)},
        {path: 'tasks', component: taskPermission(TasksView)}
      ]
    }
  ]
};
