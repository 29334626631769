import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {translate} from 'react-i18next';

@translate(['common'], {wait: true})
export default class I18n extends Component<any, any> {

  static propTypes = {
    i18nKey: PropTypes.string.isRequired,
    args: PropTypes.object
  };

  render() {

    const {t, i18nKey, args} = this.props;

    return (
      <span className='i18n'
            key={'i18n_' + i18nKey}
            data-i18n={i18nKey}
            dangerouslySetInnerHTML={{__html: t(i18nKey, args)}}/>
    );
  }
}
