import * as React from 'react';
import {PureComponent} from 'react';
import Select from './Select';
import {connect} from 'react-redux';
import Projects from '../modules/Projects';
import {PropTypes} from 'prop-types';

const mapStateToProps = ({projects}, _ownProps) => ({projects});

const mapActionsToProps = {
  getProjects: Projects.getModels
};

class ProjectSelect extends PureComponent<any, any> {

  static propTypes = {
    selectedOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    getProjects: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {

    this.props.getProjects();
  }

  modelToOption = (project) => ({
    label: project.customerName + ' - ' + project.name,
    value: project.id,
    disabled: !!project.disabled
  });

  render() {

    const {projects, selectedOptions} = this.props;

    return (
      <Select key='projects_select'
              name='form-field-name'
              models={projects.list}
              isMulti={true}
              modelToOption={this.modelToOption}
              selectedOptions={selectedOptions}
              onChange={this.props.onChange}
              disabled={this.props.readOnly}/>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectSelect);
