import * as React from 'react';
import {Router} from 'react-router';
import {connect, Provider} from 'react-redux';
import {initialize, teardown} from '../modules/App';
import * as Loader from 'react-loader';
import User from '../models/User';

const mapActionCreators = {
  initialize,
  teardown
};

const mapStateToProps = ({authenticatedUser, app, localizations}, _ownProps) => ({authenticatedUser, app, localizations});

export interface Props {
  routes: any;
  store: any;
  history: any;
  app?: any;
  initialize?: any;
  teardown?: any;
  authenticatedUser: User;
  getLocalizations: any;
}

class AppContainer extends React.Component<Props, any> {

  constructor(props) {
    super(props);
    this.state = {
      languageChanged: false,
      initialLocalizationsLoadOnce: false,
      initialLocalizationsLoaded: false
    };
  }

  componentDidMount() {

    this.props.initialize();
  }

  componentWillUnmount() {

    this.props.teardown();
  }

  componentDidUpdate() {

    const {app} = this.props;

    if (!app.initialized && !app.initializing) {

      this.props.initialize();
    }
  }

  render() {

    const {app, history, routes, store} = this.props;

    if (!app.initialized && app.initializing) {

      return (
        <div style={{height: '100%'}}>
          <Loader loaded={false} width={12} radius={35}/>
        </div>
      );
    }

    return (
      <Provider store={store}>
        <Router history={history} children={routes}/>
      </Provider>
    );
  }
}

export default connect(mapStateToProps, mapActionCreators)(AppContainer);
