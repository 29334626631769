import {createAction} from 'redux-actions';
import ReportingReports from '../models/ReportingReports';
import ModelsModule from './ModelsModule';
import {add} from './ActionsCommon';
import {infoKey} from './Notifications';
import {bind} from '../util';

const initialState = new ReportingReports();

export class ReportsModule extends ModelsModule {

  private setInvoicedRequestType: any;
  private setInvoicedRequestAction: any;
  private setInvoicedFailedType: any;
  private setInvoicedFailedAction: any;
  private setInvoicedSuccessType: string;
  private setInvoicedSuccessAction: any;
  private setReportsSelectedType: string;
  private setReportsSelectedAction: any;

  constructor() {
    super('hour-report/report', initialState);
    bind(this, this.invoiceReports, this.setReportsSelected);
  }

  invoiceReports(reportIds) {

    return (dispatch) => {

      dispatch(this.setInvoicedRequestAction());

      return dispatch(add('hour-report', 'invoice', null, reportIds))
        .then(response => dispatch(this.setInvoicedSuccessAction({response, reportIds})))
        .then(() => infoKey('report.invoiced', {amount: reportIds.length}))
        .catch(error => dispatch(this.handleError(this.setInvoicedFailedAction, error)));
    };
  }

  setReportsSelected(ids, selected) {

    return dispatch => dispatch(this.setReportsSelectedAction({ids, selected}));
  }

  getAdditionalActionHandlers() {

    return {
      [this.setInvoicedSuccessType]: (state, {payload: {reportIds = []}}) => state.setInvoiced(reportIds),
      [this.getModelsSuccessType]: (state, {payload: {results = [], reset = false, queryParams}}) => {

        const detailed = queryParams && queryParams.detailed;

        return (reset ? state.setReportsFromJS(results, detailed) : state.addReportsFromJS(results, detailed)).endOfLoading();
      },
      [this.setReportsSelectedType]: (state, {payload: {ids, selected}}) => state.setReportsSelected(ids, selected),
      [this.setInvoicedRequestType]: (state) => state.startOfInvoicing(),
      [this.setInvoicedSuccessType]: (state) => state.endOfInvoicing(undefined),
      [this.setInvoicedFailedType]: (state, {payload}) => state.endOfInvoicing(payload)
    };
  }

  protected initializeTypes() {

    super.initializeTypes();

    this.setInvoicedSuccessType = `${this.name}.INVOICED_SUCCESS`;
    this.setInvoicedRequestType = `${this.name}.INVOICED_REQUEST`;
    this.setInvoicedFailedType = `${this.name}.INVOICED_FAILED`;
    this.setReportsSelectedType = `${this.name}.REPORT_SELECTED`;
  }

  protected initializeActions() {

    super.initializeActions();

    this.setInvoicedRequestAction = createAction(this.setInvoicedRequestType);
    this.setInvoicedSuccessAction = createAction(this.setInvoicedSuccessType);
    this.setInvoicedFailedAction = createAction(this.setInvoicedFailedType);
    this.setReportsSelectedAction = createAction(this.setReportsSelectedType);
  }
}

export default new ReportsModule();
