import * as Promise from 'bluebird';
import {parse} from 'content-type';
import * as _ from 'lodash';
import ErrorCode from './ErrorCode';

const jsonType = 'application/json';

function resultWrapper(data, req) {

  if (req.status === 400 && !data.errorCode) {

    data = _.extend(data, {errorCode: ErrorCode.BAD_REQUEST});

  } else if (req.status === 401) {

    data = _.extend(data, {errorCode: ErrorCode.UNAUTHORIZED});

  } else if (req.status === 403 && !data.errorCode) {

    data = _.extend(data, {errorCode: ErrorCode.FORBIDDEN});

  } else if (req.status === 404 && !data.errorCode) {

    data = _.extend(data, {errorCode: ErrorCode.NOT_FOUND});

  } else if (req.status === 409 && !data.errorCode) {

    data = _.extend(data, {errorCode: ErrorCode.CONFLICT});

  } else if (req.status === 500 && !data.errorCode) {

    data = _.extend(data, {errorCode: ErrorCode.INTERNAL_SERVER_ERROR});

  } else if (req.status === 0) {

    data = _.extend(data, {errorCode: ErrorCode.CONNECTION_ERROR});
  }

  return {
    code: req.status,
    connectivity: req.status !== 0,
    text: req.statusText,
    data: data
  };
}

function getCookie(name) {

  let start, end;

  if (document.cookie.length > 0) {

    start = document.cookie.indexOf(name + '=');

    if (start !== -1) {

      start = start + name.length + 1;
      end = document.cookie.indexOf(';', start);

      if (end === -1) {
        end = document.cookie.length;
      }

      return decodeURI(document.cookie.substring(start, end));
    }
  }

  return '';
}

function createResult(req) {

  const contentTypeHeader = req.getResponseHeader('Content-Type');
  const type = contentTypeHeader ? parse(contentTypeHeader).type : undefined;

  if (type && type.indexOf('json') !== -1) {

    return resultWrapper(JSON.parse(req.responseText), req);

  } else {

    return resultWrapper(req.responseText, req);
  }
}

function xhr(method, url, data, token) {

  function sendRequest(req) {

    if (data) {

      if (typeof data === 'object') {

        data = JSON.stringify(data);
      }

      req.send(data);

    } else {

      req.send();
    }
  }

  // Return a new promise.F
  return new Promise(function (resolve, reject) {

    // Do the usual XHR stuff
    const req = new XMLHttpRequest();

    if (token) {

      req.open(method, url, true);
      req.setRequestHeader('Authorization', 'Bearer ' + token);

    } else {

      req.open(method, url);
    }

    if (method === 'post' || method === 'put') {

      const csfrToken = getCookie('X-CSRF-TOKEN');

      if (csfrToken) {
        req.setRequestHeader('X-CSRF-TOKEN', csfrToken);
      }
    }

    req.withCredentials = true;

    req.setRequestHeader('Content-type', jsonType + '; encoding=utf-8');

    req.onreadystatechange = function () {

      if (req.readyState === 4) {

        if (req.status === 200
          || req.status === 201
          || (req.status >= 300 && req.status < 400)) {

          resolve(createResult(req));

        } else {

          reject(createResult(req));
        }
      }
    };

    sendRequest(req);
  });
}

export const get = xhr.bind(undefined, 'get');
export const put = xhr.bind(undefined, 'put');
export const post = xhr.bind(undefined, 'post');
export const del = xhr.bind(undefined, 'delete');
