import {List} from 'immutable';
import {formatDate} from '../util';

export function registerCustomValidators() {

  const i18nRef = require('./I18n').default;
  const validate = require('validate.js');
  const moment = require('moment');

  validate.validators.isBeforeAttribute = function (value, options, key, attributes) {

    function getMomentValue(dateValue, field, startTime?) {

      const dateOnlyField = field + 'DateOnly';
      const dateOnly = attributes[dateOnlyField];
      const momentValue = moment(dateValue);

      if (dateOnly) {

        return startTime ? momentValue.startOf('day') : momentValue.endOf('day');
      }

      return momentValue;
    }

    if (attributes[options]) {

      const moment1 = getMomentValue(value, key, true);
      const moment2 = getMomentValue(attributes[options], options);

      if (moment1.diff(moment2) >= 0) {

        return i18nRef.t('validate.dateMustBeBeforeAttribute', {
          attribute: i18nRef.t('validate.attribute.' + options),
          date: formatDate(moment1, attributes[key + 'DateOnly']),
          date2: formatDate(moment2, attributes[options + 'DateOnly'])
        });
      }
    }

    return null;
  };

  validate.validators.isBeforeNow = function (value, _options, key, attributes) {

    if (value) {

      const moment1 = moment(value);
      const now = moment().endOf('minute');

      if (moment1.diff(now) >= 0) {

        const dateOnly = attributes[key + 'DateOnly'];

        return i18nRef.t('validate.dateMustBeBeforeNow', {
          date: formatDate(moment1, dateOnly),
          date2: formatDate(now)
        });
      }
    }

    return null;
  };

  validate.validators.roles = function (value) {

    if (value) {

      const list = List.isList(value) ? value : List(value);
      const hasSubject = list.find((role) => role === 'Subject');

      if (hasSubject && list.size > 1) {

        return i18nRef.t('validate.roles');
      }
    }

    return null;
  };

  validate.validators.listSize = function (value, options, _key, _attributes) {

    if (value) {

      const list = List.isList(value) ? value : List(value);

      if (list.size !== parseInt(options, 10)) {

        return i18nRef.t('validate.listSize', {length: options});
      }
    }

    return null;
  };

  validate.validators.presence.message = i18nRef.t('validate.presence');
  validate.validators.email.message = i18nRef.t('validate.email');
  validate.validators.equality.message = i18nRef.t('validate.equality');
  validate.validators.length.tooShort = i18nRef.t('validate.tooShort');
}
