import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Permission from '../../services/Permission';
import {translate} from 'react-i18next';

@translate(['common'], {wait: true})
class WithAuthorization extends Component<any, any> {

  static propTypes = {
    WrappedComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    allowed: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {

    const {WrappedComponent, allowed, t, ...rest} = this.props;

    if (allowed) {

      return <WrappedComponent {...rest} />;

    } else {

      return <h1 className={'content-center'}>{t('notAuthorized')}</h1>;
    }
  }
}

const Authorization = (allowedPermissions: Permission[]) => {

  return WrappedComponent => {

    const mapStateToProps = ({authenticatedUser}) => {

      return {
        WrappedComponent,
        allowed: authenticatedUser.hasOneOfPermissions(allowedPermissions)
      } as any;
    };

    return connect(mapStateToProps)(WithAuthorization);
  };
};

export default Authorization;
