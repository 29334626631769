import BaseModel from './BaseModel';
import {List} from 'immutable';

const defaultValues = {
  customerId: undefined,
  customerName: undefined,
  customerDisabled: undefined,
  name: undefined,
  comment: undefined,
  billingInfo: undefined,
  disabled: undefined,
  taskIds: List(),
  userIds: List()
};

const equalsKeys = ['name', 'customerId'];

const constraints = {
  name: {
    presence: true
  },
  customerId: {
    presence: true
  }
};

export default class Project extends BaseModel(defaultValues, equalsKeys, constraints)<Project> {

  constructor(js?) {
    super(js);

    return this.setListArray(['taskIds', 'userIds'], js) as Project;
  }

  fromJS(js): Project {

    return new Project(js);
  }
}
