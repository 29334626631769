import * as React from 'react';
import {Component} from 'react';
import TextColumn from './TextColumn';
import * as PropTypes from 'prop-types';
import {translate} from 'react-i18next';

@translate(['common'], {wait: true})
export default class NameColumn extends Component<any, any> {

  static propTypes = {
    saveModel: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    data: PropTypes.string,
    readOnly: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: props.data
    };
  }

  componentDidUpdate(prevProps) {

    if (prevProps.data !== this.props.data) {
      this.setState({data: this.props.data});
    }
  }

  onChange = (value) => {

    this.setState({data: value});
  };

  onEditingStart = () => {

    const {saveModel, rowData} = this.props;

    saveModel(rowData.src.set('editing', true));
  };

  onEditingDone = () => {

    const elementById = document.getElementById('disabled_' + this.props.rowData.src._id);

    if (elementById) {
      elementById.focus();
    }

    const {saveModel, rowData} = this.props;

    saveModel(rowData.src
      .set('name', this.state.data)
      .set('editing', false));
  };

  render() {

    const {rowData, readOnly, t} = this.props;

    return (
      <TextColumn key={'name_column' + rowData.src._id}
                  field='name'
                  placeholder={t('placeholder.addNew')}
                  rowData={rowData}
                  data={this.state.data}
                  onEditingStart={this.onEditingStart}
                  onEditingDone={this.onEditingDone}
                  onChange={this.onChange}
                  readOnly={readOnly}/>
    );
  }
}
