/**
 * Created by Mauritz Untamala on 05/10/15.
 */
import * as React from 'react';
import {Component, PureComponent} from 'react';
import I18n from '../../components/I18n';
import Input from '../../components/Input';
import {getFieldError} from '../../components/ErrorsMixin';
import Toggle from 'react-toggle';
import {PropTypes} from 'prop-types';
import Project from '../../modules/Project';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Permission from '../../services/Permission';
import TaskSelect from '../../components/TaskSelect';
import UserSelect from '../../components/UserSelect';

class ProjectDetails extends Component<any, any> {

  static propTypes = {
    t: PropTypes.func.isRequired,
    saveModel: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
  }

  onFieldChange = (field) => (event) => this.onFieldUpdate(field, event.target.value);

  onFieldUpdate = (field, value) => this.props.saveModel(this.props.project.set(field, value));

  getEditableField = (field, type?) => {

    const {project, t, readOnly} = this.props;
    const fieldError = getFieldError(field, project.validate(), project.error);

    return (
      <Input key={field}
             type={type ? type : 'text'}
             label={<I18n i18nKey={field}/>}
             labelClassName='col-xs-2'
             error={fieldError}
             onChange={this.onFieldChange(field)}
             value={project.get(field)}
             wrapperClassName='col-sm-10'
             placeholder={t('placeholder.' + field)}
             disabled={readOnly}/>
    );
  };

  toggleDisabled = () => {

    const {saveModel, project} = this.props;
    saveModel(project.set('disabled', !project.disabled));
  };

  onTasksChange = (taskIds) => {

    const {saveModel, project} = this.props;
    saveModel(project.set('taskIds', project.taskIds.clear().concat(taskIds)));
  };

  onUsersChange = (userIds) => {

    const {saveModel, project} = this.props;
    saveModel(project.set('userIds', project.userIds.clear().concat(userIds)));
  };

  render() {

    const {project, t, readOnly} = this.props;

    return (
      <div className='form-horizontal'>

        {this.getEditableField('name')}

        <div className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='column.disabled'/></label>

          <div className='col-sm-10'>
            <Toggle
              disabled={project.customerDisabled || readOnly}
              defaultChecked={!project.disabled}
              onChange={this.toggleDisabled}/>
          </div>
        </div>

        {this.getEditableField('comment', 'textarea')}

        {this.getEditableField('billingInfo', 'textarea')}

        <div className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='projectView.hourReports'/></label>
          <div className='col-sm-10'>
            <a href={'/report?projects=' + project.id}>{t('projectView.hourReportsLink')}</a>
          </div>
        </div>

        <div className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='tasks'/></label>

          <div className='col-sm-10'>
            <TaskSelect key='project_tasks'
                        onChange={this.onTasksChange}
                        selectedOptions={project.taskIds.toArray()}
                        readOnly={readOnly}/>
          </div>
        </div>

        <div className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='users'/></label>

          <div className='col-sm-10'>
            <UserSelect key='project_users'
                        onChange={this.onUsersChange}
                        selectedOptions={project.userIds.toArray()}
                        readOnly={readOnly}/>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({project, authenticatedUser}, ownProps) => {

  const projectId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined;
  const readOnly = !authenticatedUser.hasPermission(Permission.WriteProject);

  return {project, projectId, readOnly, authenticatedUser};
};

const mapActionsToProps = {
  getModel: Project.getModel,
  saveModel: Project.saveModelDebounced
};

@translate(['common'], {wait: true})
class ProjectView extends PureComponent<any, any> {

  static propTypes = {
    t: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    getModel: PropTypes.func.isRequired,
    saveModel: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    authenticatedUser: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    const {getModel, projectId} = this.props;
    getModel(projectId);
  }

  projectDetails = () => {

    if (!this.props.project) return;

    return <ProjectDetails {...this.props}/>;
  };

  title = () => <I18n i18nKey='projectView.title' args={{name: this.props.project.name ? this.props.project.name : '-'}}/>;

  render() {

    return (
      <div key='project-view-container' className='container'>
        <div className='page-title'>
          {this.title()}
        </div>
        {this.projectDetails()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectView);
