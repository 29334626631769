import {List} from 'immutable';
import * as _ from 'lodash';
import moment from 'moment-es6';
import BaseModel from './BaseModel';
import {parseBooleanStyle, stringToIntArray} from '../util';
import {setDateFields} from './Common';

const interestedKeys = [
  'from',
  'to',
  'invoiced',
  'detailed',
  'columnName',
  'sort',
  'filter',
  'showDisabled',
  'users',
  'customers',
  'projects',
  'tasks'
];

const defaultValues = {
  from: undefined,
  to: undefined,
  users: List(),
  customers: List(),
  projects: List(),
  tasks: List(),
  invoiced: undefined,
  detailed: undefined,
  columnName: undefined,
  sort: undefined,
  filter: undefined,
  showDisabled: false,
  dateOnly: false
};

const idLists = ['users', 'customers', 'projects', 'tasks'];
const dateFields = ['from', 'to'];

export default class Criteria extends BaseModel(defaultValues, interestedKeys)<Criteria> {

  from: any;
  to: any;
  users: List<number>;
  customers: List<number>;
  projects: List<number>;
  tasks: List<number>;
  invoiced: undefined;
  detailed: undefined;
  showDisabled: undefined;
  dateOnly: undefined;

  static getInitialCriteriaWithoutDateRange(js = {}) {

    return new Criteria(js);
  }

  static fromQuery(js: any = {}, dateOnly = false): Criteria {

    if (js) {
      idLists.forEach(field => {
        js[field] = stringToIntArray(js[field]);
      });
    }

    return new Criteria(_.merge({}, js, {dateOnly}));
  }

  static getInitialCriteria(dateOnly = false) {

    const dayAtPreviousMonth = moment().subtract(1, 'months');

    const from = dayAtPreviousMonth.clone().startOf('month');
    const to = dayAtPreviousMonth.clone().endOf('month');

    return new Criteria({from, to, dateOnly});
  }

  static getCriteriaWithoutInitialDateRage = ({query}) => {

    const hasParams = !!_.find(Object.keys(query), (key) => key !== '');

    if (hasParams) {

      return Criteria.fromQuery(query);

    } else {

      return Criteria.getInitialCriteriaWithoutDateRange();
    }
  };

  constructor(js?) {
    super(js);

    if (js) {

      let criteria = this.setListArray(idLists, js) as Criteria;

      criteria = criteria.set('invoiced', parseBooleanStyle(js.invoiced)) as Criteria;
      criteria = criteria.set('detailed', parseBooleanStyle(js.detailed)) as Criteria;
      criteria = criteria.set('showDisabled', parseBooleanStyle(js.showDisabled)) as Criteria;

      return setDateFields(criteria, dateFields);
    }
  }

  fromJS(js: any = {}): Criteria {

    return new Criteria(js);
  }

  listToParams(queryParams, fields) {

    _.each(fields, (field) => {
      let list = this[field];

      if (list && !list.isEmpty()) {
        queryParams[field] = list.toArray();
      }
    });
  }

  fieldsToParams(queryParams, fields) {

    _.each(fields, (field) => {
      if (this[field] !== undefined) {
        queryParams[field] = this[field];
      }
    });
  }

  getQueryParams() {

    let queryParams = {};

    this.listToParams(queryParams, ['users', 'customers', 'projects', 'tasks']);
    this.fieldsToParams(queryParams, ['invoiced', 'detailed', 'filter', 'columnName', 'sort', 'showDisabled']);

    _.each(['from', 'to'], (dateField) => {
      if (this[dateField]) {
        queryParams[dateField] = this.dateOnly
          ? this[dateField].format('YYYY-MM-DD')
          : this[dateField].toISOString();
      }
    });

    return queryParams;
  }
}
