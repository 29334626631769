import {Record} from 'immutable';

const state = Record({
  app: undefined,
  authenticatedUser: undefined,
  customer: undefined,
  customers: undefined,
  hourReport: undefined,
  hourReports: undefined,
  location: undefined,
  pagination: undefined,
  project: undefined,
  projects: undefined,
  customerProjects: undefined,
  userProjects: undefined,
  reports: undefined,
  routing: undefined,
  task: undefined,
  tasks: undefined,
  user: undefined,
  users: undefined,
  expectedHours: undefined
});

export default state;
