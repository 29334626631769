import ReportingReport from './ReportingReport';
import Collection from './ImmutableCollection';
import {generateUUID} from '../util';
import * as _ from 'lodash';
import {List} from 'immutable';

export default class ReportingReports extends Collection(ReportingReport, {isInvoicing: false})<ReportingReport> {

  setInvoiced(reportIds) {

    const updatedList = this.list.map(report => {
      return _.includes(reportIds, report.id) ? report.set('invoiced', true) : report;
    });

    return this.set('list', updatedList);
  }

  setReportsFromJS(reports, detailed) {

    return this.setModelsFromJS(this.adjustResults(reports, detailed));
  }

  addReportsFromJS(reports, detailed) {

    return this.addModelsFromJS(this.adjustResults(reports, detailed));
  }

  getTotalHours() {

    return this.list.reduce((accu, report) => accu + report.hours, 0);
  }

  setReportsSelected(ids, selected) {

    const list = this.list.map(r => _.includes(ids, r.id) ? r.set('selected', selected) : r) as List<ReportingReport>;

    return this.setList(list) as ReportingReports;
  }

  startOfInvoicing() {

    return this.set('isInvoicing', true)
      .set('error', null);
  }

  endOfInvoicing(error) {

    return this.set('isInvoicing', false)
      .set('error', error);
  }

  private adjustResults(reports, detailed) {

    if (detailed) {

      return reports;

    } else {

      return _.values(reports
        .reduce((accu, report) => {

          const key = `${report.userId}:${report.projectId}:${report.taskId}`;
          const current = accu[key];

          if (!current) {

            accu[key] = _.omit(report, 'description', 'date', 'id');
            accu[key].id = generateUUID();
            accu[key].ids = [report.id];

            return accu;
          }

          current.hours = current.hours + report.hours;
          current.ids.push(report.id);

          return accu;
        }, {}));
    }
  }
}
