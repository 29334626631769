import Customer from '../models/Customer';
import ModelModule from './ModelModule';

const initialState = new Customer();

export class CustomerModule extends ModelModule<Customer> {

  constructor() {
    super('customer', initialState);
  }
}

export default new CustomerModule();
