/**
 * Created by Mauritz Untamala on 27/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import {translate} from 'react-i18next';
import * as ReactTooltip from 'react-tooltip';

@translate(['common'], {wait: true})
export default class StatusColumn extends Component<any, any> {

  private _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      showOk: false,
      showFail: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps({data, rowData}) {

    if (this.props.data && !data && !rowData.src.error) {

      this.setState({'showOk': true});

      setTimeout(() => {
        if (this._isMounted) {
          this.setState({'showOk': false});
        }
      }, 2000);

    } else if (rowData.src.error) {

      this.setState({showOk: false, showFail: true});

    } else if (data) {

      this.setState({showOk: false, showFail: false});
    }
  }

  componentDidUpdate(_prevProps, prevState) {

    if (!prevState.showFail && this.state.showFail) {
      ReactTooltip.rebuild();
    }
  }

  render() {

    if (this.props.data) {

      return <i className='fa fa-refresh fa-spin fa-lg iconSaving'/>;

    } else if (this.state.showOk) {

      return <i className='fa fa-check-circle fa-lg iconOK'/>;

    } else if (this.state.showFail) {

      const error = this.props.rowData.src.error;

      return <i className='fa fa-exclamation-circle fa-lg iconFail' data-tip={error ? error.message || this.props.t(error.errorCode) : ''}/>;

    } else {

      return <div/>;
    }
  }
}
