/**
 * Created by Mauritz Untamala on 18/08/15.
 */
import * as _ from 'lodash';

export function getFieldError(field, errors, modelError) {

  let fieldError = errors ? errors[field] : undefined;
  fieldError = _.isArray(fieldError) ? fieldError.join('. ') : fieldError;

  return modelError && modelError.fieldErrors
  && modelError.fieldErrors[field] ? ((fieldError ? fieldError : '') + modelError.fieldErrors[field]) : fieldError;
}
