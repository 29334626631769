import Project from '../models/Project';
import ModelModule from './ModelModule';
import {infoKey} from './Notifications';

const initialState = new Project();

export class ProjectModule extends ModelModule<Project> {

  constructor() {
    super('project', initialState);
  }

  protected onCreateSuccess(model) {

    return _dispatch => infoKey(`${this.name}.saved`, this.saveSuccessMessageProps(model));
  }

  protected onUpdateSuccess(model) {

    return _dispatch => infoKey(`${this.name}.saved`, this.saveSuccessMessageProps(model));
  }

  protected saveSuccessMessageProps(model) {

    return {name: model.name};
  }

  protected deleteSuccessMessageProps(model) {

    return {name: model.name};
  }
}

export default new ProjectModule();
