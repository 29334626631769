/**
 * Created by Mauritz Untamala on 02/01/16.
 */
import * as React from 'react';
import {Component} from 'react';
import {pingAuthorization} from '../modules/App';
import {connect} from 'react-redux';
import * as classnames from 'classnames';
import {translate} from 'react-i18next';

const timeToShowOk = 3000;

const mapStateToProps = ({app}, _ownProps) => {
  return {app};
};

const mapActionToProps = {
  pingAuthorization
};

@translate(['common'], {wait: true})
class Connectivity extends Component<any, any> {

  private mounted;

  constructor(props) {
    super(props);
    this.state = {showOk: false};
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {

    if (!prevProps.app.isConnected() && this.props.app.isConnected()) {

      this.setState({showOk: true}, () => {

        setTimeout(() => {
          if (this.mounted) {
            this.setState({showOk: false});
          }
        }, timeToShowOk);
      });

    } else if (prevProps.app.isConnected() && !this.props.app.isConnected() && this.state.showOk) {

      this.setState({showOk: false});
    }
  }

  currentState = () => {

    if (this.props.app.isConnected()) {
      return this.props.t('connected');
    } else {
      return this.props.t('noConnectivity');
    }
  };

  stateIcon = () => {

    if (this.state.connectivity) {
      return <i className='fa fa-chain'/>;
    } else {
      return <i className='fa fa-chain-broken'/>;
    }
  };

  connectionOutage = () => {

    const classNames = classnames({
      'message-container': true,
      'ok': this.state.showOk
    });

    return (
      <div className='modal-backdrop'>
        <div className='centered-container'>
          <div className='centered'>
            <div className={classNames}>
              <div className='connectivity-icon'>{this.stateIcon()}</div>
              <div className='connectivity-message'>
                <span>{this.currentState()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>);
  };

  render() {

    const {app} = this.props;
    const connectivity = app.isConnected();

    if (!connectivity || this.state.showOk) {
      return this.connectionOutage();
    } else {
      return <div/>;
    }
  }
}

export default connect(mapStateToProps, mapActionToProps)(Connectivity);
