import AuthLayout from '../../layouts/AuthLayout';
import LoginView from './containers/LoginView';
import LogoutView from './containers/LogoutView';

export default {
  component: AuthLayout,
  childRoutes: [
    {path: 'login', component: LoginView},
    {path: 'logout', component: LogoutView}
  ]
};
