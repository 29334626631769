import Customers from '../models/Customers';
import ModelsModule from './ModelsModule';
import Customer from './Customer';

const initialState = new Customers();

class CustomersModule extends ModelsModule {

  constructor() {
    super('customer', initialState);
  }

  protected getAdditionalActionHandlers() {

    return {
      [Customer.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload, true),
      [Customer.getUpdateModelRequestType()]: (state, {payload}) => state.addModel(payload.startOfSaving()),
      [Customer.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Customer.getUpdateModelFailedType()]: (state, {payload: {error, model}}) => {

        if (model) {
          return state.addModel(model.endOfSaving(error));
        }

        return state;
      },
      [Customer.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new CustomersModule();
