import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import Toggle from 'react-toggle';

export default class DisabledColumn extends Component<any, any> {

  static propTypes = {
    saveModel: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    data: PropTypes.bool,
    readOnly: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
  }

  toggleDisabled = () => {

    const {saveModel, rowData, data} = this.props;
    saveModel(rowData.src.set('disabled', !data));
  };

  render() {

    const {rowData, data, readOnly} = this.props;

    return <Toggle
      id={'disabled_' + rowData.src._id}
      checked={!data}
      onChange={this.toggleDisabled}
      disabled={readOnly}/>;
  }
}
