/**
 * Created by Mauritz Untamala on 27/08/15.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import Tasks from '../modules/Tasks';
import Select from './Select';
import Criteria from '../models/Criteria';

const mapStateToProps = ({tasks}, _ownProps) => ({tasks});

const mapActionsToProps = {
  getTasks: Tasks.getModels
};

class TaskSelect extends PureComponent<any, any> {

  static propTypes = {
    getTasks: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    tasks: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props, 'tasks');
  }

  componentDidMount() {

    const taskCriteria = new Criteria({showDisabled: true});
    this.props.getTasks(taskCriteria.getQueryParams());
  }

  modelToOption = model => ({
    label: model.name,
    value: model.id,
    disabled: !!model.disabled
  });

  render() {

    return (
      <Select key='tasks_select'
              name='form-field-name'
              models={this.props.tasks.list}
              modelToOption={this.modelToOption}
              selectedOptions={this.props.selectedOptions}
              isMulti={true}
              allowRemoveDisabledItem={false}
              onChange={this.props.onChange}
              disabled={this.props.readOnly}/>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(TaskSelect);
