/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import BaseInput from './BaseInput';
import * as _ from 'lodash';

export default class Input extends Component<any, any> {

  private input;

  constructor(props) {
    super(props);
    this.state = {value: props.value};
  }

  componentWillReceiveProps({value}) {
    if (this.props.value !== value) {
      this.setState({value});
    }
  }

  onChange = (event) => {
    event.persist();
    this.setState({value: event.target.value}, () => this.props.onChange(event));
  };

  focus = () => this.input.getWrappedInstance().focus();

  render() {

    return (
      <BaseInput ref={input => this.input = input}
                 onChange={this.onChange}
                 value={this.state.value}
                 {..._.omit(this.props, ['onChange', 'value'])} />
    );
  }
}
