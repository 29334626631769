/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import BaseInput from './BaseInput';
import * as _ from 'lodash';
import {bindOn, formatHour, hourRegex} from '../util';
import {translate} from 'react-i18next';

@translate(['common'], {wait: true})
export default class HourInput extends Component<any, any> {

  constructor(props, context) {
    super(props, context);
    bindOn(this);
    this.state = {value: props.value};
  }

  componentWillReceiveProps({value}) {
    if (this.props.value !== value) {
      this.setState({value});
    }
  }

  isHour = (value) => value ? !!value.toString().match(hourRegex) : false;

  onChange = (event) => {
    event.persist();

    let value = event.target.value;

    if (value) {

      value = value.replace(',', '.');
    }

    if (this.isHour(value) || !value) {

      value = value ? formatHour(value) : null;
      this.setState({value}, () => this.props.onChange(value));
    }
  };

  render() {

    const {t} = this.props;

    return (
      <BaseInput onChange={this.onChange}
                 placeholder={t('column.hours')}
                 value={this.state.value}
                 {..._.omit(this.props, ['onChange', 'value'])} />
    );
  }
}
