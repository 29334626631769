'use strict';
/**
 * Created by Mauritz Untamala on 09/11/2016.
 */
import * as React from 'react';
import InlineConfirmButton from 'react-inline-confirm';
import I18n from '../services/I18n';

const ConfirmButton = ({className, onClick, children, disabled = false}) => {

  const textValues = ['', ' ' + I18n.t('confirm'), ''];

  return <InlineConfirmButton className={className}
                              textValues={textValues}
                              showTimer
                              disabled={disabled}
                              onClick={onClick}
                              isExecuting={false}>
    {children}
  </InlineConfirmButton>;
};

export default ConfirmButton;
