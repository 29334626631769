import * as React from 'react';
import {PureComponent} from 'react';
import Select from './Select';
import Input from './Input';
import HourInput from './HourInput';
import {PropTypes} from 'prop-types';
import Button from './ConfirmButton';

export default class HourReportingDayRow extends PureComponent<any, any> {

  static propTypes = {
    deleteModel: PropTypes.func.isRequired,
    projects: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  private _isMounted = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      showOk: false,
      report: props.report
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps({report}) {

    if (this.props.report.isSaving && !report.isSaving) {

      this.setState({showOk: true});

      setTimeout(() => {
        if (this._isMounted) {
          this.setState({showOk: false});
        }
      }, 2000);
    }

    if (report.isSaving) {
      this.setState({showOk: false});
    }

    if (this.props.report !== report) {

      // Update state model from props when model is changed to persisted
      if (!this.props.report.id && report.id) {

        this.setState({report});

      } else if (this.state.report.error !== report.error) {

        // If report has error, populate it to local state

        this.setState({report: this.state.report.set('error', report.error)});
      }
    }
  }

  onDescriptionChange = (event) => this.updateModel(this.state.report.set('description', event.target.value));

  onHoursChange = (value) => this.updateModel(this.state.report.set('hours', value));

  onProjectChange = (projectId) => {

    const {report} = this.state;
    const {projects} = this.props;
    const project = projects.getModelById(projectId);
    const taskId = project && project.taskIds.includes(report.taskId) ? report.taskId : null;

    this.updateModel(
      report
        .set('projectId', projectId)
        .set('taskId', taskId)
    );
  };

  onTaskChange = (selectedId) => this.updateModel(this.state.report.set('taskId', selectedId));

  getButtons = () => {

    const {report} = this.state;

    return (
      <Button key={'remove_' + report._id}
              className='btn btn-default'
              disabled={report.invoiced || !report.id}
              onClick={this.deleteReport}>
        <i className='fa fa-trash fa-lg'/>
      </Button>
    );
  };

  getStateIcons = () => {

    const {showOk, report: {isSaving, error}} = this.state;

    if (isSaving) {

      return <i id='iconSaving' className='fa fa-refresh fa-spin fa-lg iconSaving'/>;

    } else if (showOk) {

      return <i id='iconOK' className='fa fa-check-circle fa-lg iconOK'/>;

    } else if (error) {

      const {fieldErrors, message, errorCode} = error;
      const {t} = this.props;

      const dataTip = message
        ? message
        : fieldErrors
          ? Object.keys(fieldErrors).map(field => `${t(field)}: ${fieldErrors[field].join(' ')}`).join(' ')
          : errorCode
            ? t(errorCode)
            : '';

      return <i id='iconFail'
                className='fa fa-exclamation-circle fa-lg iconFail'
                data-tip={dataTip}/>;
    }
  };

  updateModel = (report) => {

    this.setState({report}, () => {

      if (!report.id && !report.isValid()) {
        return;
      }

      this.props.onChange(report);
    });
  };

  deleteReport = () => this.props.deleteModel(this.props.report);

  getProjects = () => {

    const {projects} = this.props;
    const {report} = this.state;

    return projects.list
      .filter((project) => {

        // Show disabled project only if report references it
        if (project.disabled && project.id === report.projectId) {
          return true;
        }

        // Otherwise filter based on disabled
        return !project.disabled;
      })
      .sortBy(p => p.customerName + ' - ' + p.name);
  };

  getTasks = () => {

    const {projects, tasks} = this.props;
    const {report} = this.state;
    const project = projects.getModelById(report.projectId);

    return tasks.list
      .filter((task) => {

        // Show only tasks related to selected project
        if (!project || !project.taskIds.includes(task.id)) {
          return false;
        }

        // Show disabled tasks only if report references it
        if (task.disabled && task.id === report.taskId) {
          return true;
        }

        // Otherwise filter based on disabled
        return !task.disabled;
      })
      .sortBy(t => t.name);
  };

  projectToOption = (project) => ({
    value: project.id,
    label: project.customerName + ' - ' + project.name,
    disabled: !!project.disabled
  });

  taskToOption = (task) => ({
    value: task.id,
    label: task.name,
    disabled: !!task.disabled
  });

  render() {

    const {report} = this.state;
    const {t, tasks} = this.props;

    const taskDisabled = tasks.isTaskDisabled(report.taskId);
    // Row will be disabled when there is a report AND that report has been invoiced OR its task is disabled
    const rowDisabled = (report.invoiced || taskDisabled) && report.id;
    return (
      <div className='report-row'>
        <div className='project'>
          <Select key='project'
                  name='project'
                  placeholder={t('column.project')}
                  modelToOption={this.projectToOption}
                  selectedOptions={report.projectId}
                  models={this.getProjects()}
                  onChange={this.onProjectChange}
                  isDisabled={rowDisabled}
          />
        </div>
        <div className='task'>
          <Select key='task'
                  name='task'
                  placeholder={t('column.task')}
                  modelToOption={this.taskToOption}
                  selectedOptions={report.taskId}
                  models={this.getTasks()}
                  onChange={this.onTaskChange}
                  isDisabled={rowDisabled}
          />
        </div>
        <div key='hours' className='hours'>
          <HourInput onChange={this.onHoursChange} value={report.hours} disabled={rowDisabled}/>
        </div>
        <div key='description' className='description'>
          <div className='input-wrapper'>
            <Input placeholder={t('column.description')}
                   onChange={this.onDescriptionChange}
                   value={report.description}
                   disabled={rowDisabled}/>
          </div>
          <div className='actions-wrapper'>
            {this.getButtons()}
          </div>
          <div className='state-wrapper'>
            {this.getStateIcons()}
          </div>
        </div>
      </div>
    );
  }
}
