import {createAction, handleActions} from 'redux-actions';
import {get} from './ActionsCommon';
import * as _ from 'lodash';
import {PaginationContext} from '../models/Pagination';
import {resetPagination, setPagination} from './Pagination';
import {bind, logDebug} from '../util';
import {updateCriteria} from './Location';
import {warningKey} from './Notifications';
import ErrorCode from '../services/ErrorCode';
import ModuleOptions from './ModuleOptions';
import I18n from '../services/I18n';

export default class ModelsModule {

  protected name: string;
  protected initialState: any;
  protected path: string;
  protected paginationContext: PaginationContext;
  protected pageSize: number;
  protected resetModelsType: string;
  protected getModelSuccessType: string;
  protected getModelsRequestType: string;
  protected getModelsSuccessType: string;
  protected getModelsFailedType: string;
  protected resetModelsAction: any;
  protected getModelsRequestAction: any;
  protected getModelSuccessAction: any;
  protected getModelsSuccessAction: any;
  protected getModelsFailedAction: any;

  constructor(name, initialState, {path = null, paginationContext = null, pageSize = 10}: ModuleOptions = {}) {
    this.name = name;
    this.initialState = initialState;
    this.path = path || name;
    this.paginationContext = paginationContext;
    this.pageSize = pageSize;
    this.initializeTypes();
    this.initializeActions();
    bind(
      this,
      this.updateCriteria,
      this.getModel,
      this.getModels,
      this.resetModels
    );
  }

  public updateCriteria(location, criteria) {

    return dispatch => {

      dispatch(updateCriteria(location, criteria));
      const queryParams = criteria.getQueryParams();
      return dispatch(this.getModels(queryParams, true));
    };
  }

  public getModel(id, queryParams?) {

    return (dispatch) => {

      dispatch(this.getModelsRequestAction());

      return dispatch(get(this.path, id, queryParams))
        .then(response => dispatch(this.getModelSuccessAction(response)))
        .catch(err => dispatch(this.getModelsFailedAction(err)));
    };
  }

  public getModels(queryParams?, reset = false, path = this.path, pathComponents = null) {

    return (dispatch, getState) => {

      dispatch(this.getModelsRequestAction());

      let pagination = this.getPagination(getState());

      if (pagination) {

        if (reset) {
          pagination.offset = 0;
        }

        queryParams = _.merge({}, queryParams, pagination);
      }

      return dispatch(get(path, pathComponents, queryParams))
        .then(response => {

          if (pagination) {

            const {results, nextPage} = response;
            pagination.offset = nextPage;
            pagination.hasMore = nextPage && results.length >= pagination.limit;

            dispatch(setPagination(this.paginationContext, pagination));

            return dispatch(this.receiveModels(results, reset, queryParams));

          } else {

            return dispatch(this.receiveModels(response, reset, queryParams));
          }
        })
        .catch(err => dispatch(this.getModelsFailedAction(err)));
    };
  }

  public receiveModels(results, reset = false, queryParams?) {

    return dispatch => dispatch(this.getModelsSuccessAction({results, reset, queryParams}));
  }

  public resetModels() {

    return dispatch => {

      if (this.paginationContext) {

        dispatch(resetPagination(this.paginationContext));
      }

      dispatch(this.resetModelsAction());
    };
  }

  public getActionHandlers() {

    const actionHandlers = {
      [this.getModelsRequestType]: (state) => state.startOfLoading(),
      [this.getModelSuccessType]: (state, {payload}) => state.addModelFromJS(payload).endOfLoading(),
      [this.getModelsSuccessType]: (state, {payload: {results = [], reset = false}}) => {
        return (reset ? state.setModelsFromJS(results) : state.addModelsFromJS(results)).endOfLoading();
      },
      [this.getModelsFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.resetModelsType]: () => this.initialState
    };

    return _.merge(actionHandlers, this.getAdditionalActionHandlers());
  }

  public getReducer() {

    return handleActions(this.getActionHandlers(), this.initialState);
  }

  protected getAdditionalActionHandlers() {

    return null;
  }

  protected initializeTypes() {

    this.resetModelsType = `${this.name}.RESET_MODELS`;

    this.getModelSuccessType = `${this.name}.GET_MODEL_SUCCESS`;

    this.getModelsRequestType = `${this.name}.GET_MODELS_REQUEST`;
    this.getModelsSuccessType = `${this.name}.GET_MODELS_SUCCESS`;
    this.getModelsFailedType = `${this.name}.GET_MODELS_FAILED`;
  }

  protected initializeActions() {

    this.resetModelsAction = createAction(this.resetModelsType);

    this.getModelSuccessAction = createAction(this.getModelSuccessType);

    this.getModelsRequestAction = createAction(this.getModelsRequestType);
    this.getModelsSuccessAction = createAction(this.getModelsSuccessType);
    this.getModelsFailedAction = createAction(this.getModelsFailedType);
  }

  protected handleError(action, error) {

    return (dispatch, getState) => {

      const errorCode = error.data && error.data.errorCode;

      if (errorCode) {

        switch (errorCode) {
          case ErrorCode.BAD_REQUEST:
          case ErrorCode.CONFLICT:
          case ErrorCode.CONNECTION_ERROR:
          case ErrorCode.FORBIDDEN:
          case ErrorCode.INTERNAL_SERVER_ERROR:
          case ErrorCode.NOT_FOUND:
            warningKey('networkError.' + errorCode);
            break;
          case ErrorCode.UNAUTHORIZED:
            const {authenticatedUser} = getState();
            if (authenticatedUser.authenticated) {
              warningKey('networkError.' + errorCode);
            } else {
              logDebug('Received unauthorized response for unauthenticated user.');
            }
            break;
          default:
            if (I18n.exists(errorCode)) {
              warningKey(errorCode);
            } else {
              warningKey('networkError.generalError');
            }
            break;
        }
      }

      return dispatch(action(error.data ? error.data : error));

    };
  }

  private getPagination(state) {

    if (!this.paginationContext) {
      return null;
    }

    const {offset} = state.pagination.get(this.paginationContext);

    return {
      offset: offset || 0,
      limit: this.pageSize
    } as any;
  }
}
