import {createAction, handleActions} from 'redux-actions';
import {get} from './ActionsCommon';
import ExpectedHoursModel from '../models/ExpectedHours';
import {warningKey} from './Notifications';
import {bindAll, createMonthRange, createWeekRange, createFromDateToNowRange, logDebug} from '../util';
import ErrorCode from '../services/ErrorCode';
import I18n from '../services/I18n';
import {DateRange} from 'moment-range';
import {Moment} from 'moment';

class ExpectedHours {

  private static readonly dateFormat = 'YYYY-MM-DD';
  private readonly path = 'hour-report/expected-hours';
  private readonly name = 'ExpectedHours';
  private initialState = new ExpectedHoursModel();

  private resetType: string;
  private getRequestType: string;
  private getSuccessType: string;
  private getFailedType: string;

  private resetAction: any;
  private getRequestAction: any;
  private getSuccessAction: any;
  private getFailedAction: any;

  constructor() {
    bindAll(this);
    this.initializeTypes();
    this.initializeActions();
  }

  public getExpectedHoursForMonth(date: Moment) {

    return this.getExpectedHours(createMonthRange(date));
  }

  public getExpectedHoursForWeek(date: Moment) {

    return this.getExpectedHours(createWeekRange(date));
  }

  public getExpectedHoursFromStartMonthToNow(date: Moment) {

    return this.getExpectedHours(createFromDateToNowRange(date, 'month'));
  }

  public getExpectedHoursFromStartWeekToNow(date: Moment) {

    return this.getExpectedHours(createFromDateToNowRange(date, 'week'));
  }

  public getExpectedHours(range: DateRange) {

    return (dispatch) => {

      const {start, end} = range;

      if (end.isBefore(start)) {
        return;
      }

      const queryParams = {
        from: start.format(ExpectedHours.dateFormat),
        to: end.format(ExpectedHours.dateFormat)
      };

      dispatch(this.getRequestAction());

      return dispatch(get(this.path, undefined, queryParams))
        .then(response => dispatch(this.getSuccessAction({range, response})))
        .catch(error => dispatch(this.handleError(this.getFailedAction, error)));
    };
  }

  public resetModel() {

    return dispatch => dispatch(this.resetAction());
  }

  public getActionHandlers() {

    const actionHandlers = {
      [this.getRequestType]: (state) => state.startOfLoading(),
      [this.getSuccessType]: (state, {payload: {range, response}}) => state.addResult(range, response),
      [this.getFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.resetType]: () => this.initialState
    };

    return actionHandlers;
  }

  public getReducer() {

    return handleActions(this.getActionHandlers(), this.initialState);
  }

  protected initializeTypes() {

    this.resetType = `${this.name}.RESET`;

    this.getRequestType = `${this.name}.GET_REQUEST`;
    this.getSuccessType = `${this.name}.GET_SUCCESS`;
    this.getFailedType = `${this.name}.GET_FAILED`;
  }

  protected initializeActions() {

    this.resetAction = createAction(this.resetType);

    this.getRequestAction = createAction(this.getRequestType);
    this.getSuccessAction = createAction(this.getSuccessType);
    this.getFailedAction = createAction(this.getFailedType);
  }

  protected handleError(action, error, model?) {

    return (dispatch, getState) => {

      const errorCode = error.data && error.data.errorCode;

      if (errorCode) {

        switch (errorCode) {
          case ErrorCode.BAD_REQUEST:
          case ErrorCode.CONFLICT:
          case ErrorCode.CONNECTION_ERROR:
          case ErrorCode.FORBIDDEN:
          case ErrorCode.INTERNAL_SERVER_ERROR:
          case ErrorCode.NOT_FOUND:
            warningKey('networkError.' + errorCode);
            break;
          case ErrorCode.UNAUTHORIZED:
            const {authenticatedUser} = getState();
            if (authenticatedUser.authenticated) {
              warningKey('networkError.' + errorCode);
            } else {
              logDebug('Received unauthorized response for unauthenticated user.');
            }
            break;
          default:
            if (I18n.exists(errorCode)) {
              warningKey(errorCode);
            } else {
              warningKey('networkError.generalError');
            }
        }
      }

      return dispatch(action({error: error.data ? error.data : error, model}));
    };
  }
}

export default new ExpectedHours();
