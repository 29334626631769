/**
 * Created by Mauritz Untamala on 08/11/15.
 */
import * as React from 'react';
import {Component} from 'react';
import I18n from './I18n';
import {PropTypes} from 'prop-types';

export default class DownloadButton extends Component<any, any> {

  static propTypes = {
    type: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    contents: PropTypes.func.isRequired,
    labelKey: PropTypes.string.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  onHandleDownloadClick = (event) => {

    const blob = new Blob([this.props.contents()], {
      type: this.props.type
    });

    // create hidden link
    const element = document.createElement('a');
    document.body.appendChild(element);
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.setAttribute('download', this.props.filename);
    element.style.display = '';

    element.click();

    document.body.removeChild(element);
    event.stopPropagation();
  };

  render() {

    return (
      <button className='btn btn-default' onClick={this.onHandleDownloadClick}>
        <I18n i18nKey={this.props.labelKey}/>
      </button>
    );
  }
}
