import ModelModule from './ModelModule';
import User from '../models/User';
import {add} from './ActionsCommon';
import {infoKey} from './Notifications';
import {bind} from '../util';
import UserProjects from './UserProjects';

const initialState = new User();

export class UserModule extends ModelModule<User> {

  constructor() {
    super('user', initialState);
    bind(this, this.updateUserProjects);
  }

  updateUserProjects(userId, selectedProjects) {

    return (dispatch, getState) => {

      const user = getState().user;

      dispatch(this.updateModelRequestAction(user));

      return dispatch(add(this.name, `${userId}/projects`, null, selectedProjects.map(p => p.id).toArray()))
        .then((response) => {

          dispatch(this.updateModelSuccessAction(user.toJS()));
          dispatch(this.onUpdateSuccess(user));

          return dispatch(UserProjects.receiveModels(response));
        })
        .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error, user)));
    };
  }

  protected onUpdateSuccess(model) {

    return _dispatch => infoKey(`${this.name}.saved`, this.saveSuccessMessageProps(model));
  }

  protected saveSuccessMessageProps(model) {

    return {name: model.displayName};
  }
}

export default new UserModule();
