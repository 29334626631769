import AppLayout from '../layouts/AppLayout';
import AuthenticationRoute from './Authentication';
import AuthenticatedRoute from './Authenticated';
import Error from './Error';

export const createRoutes = (store) => {
  return {
    path: '/',
    childRoutes: [
      {
        component: AppLayout,
        indexRoute: {
          onEnter: (_nextState, replace) => {

            const user = store.getState().authenticatedUser;

            return replace(user.authenticated ? '/hour-report' : '/login');
          }
        }
      },
      AuthenticationRoute,
      AuthenticatedRoute,
      {path: 'error/:id', component: Error}
    ]
  };
};

export default createRoutes;
