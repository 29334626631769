import * as React from 'react';

interface Props {
  children: any;
}

export const AuthLayout: React.SFC<Props> = ({children}) => (
  <div className='auth-layout'>
    {children}
  </div>
);

export default AuthLayout;
