import * as React from 'react';
import {connect} from 'react-redux';
import AuthenticatedUser from '../../../modules/AuthenticatedUser';

const mapActionCreators = {
  logout: AuthenticatedUser.logout
};

const mapStateToProps = (state) => ({user: state.user});

interface Props {
  user: any;
  logout;
  replace;
}

class LogoutView extends React.Component<Props, any> {

  componentDidMount() {

    this.props.logout();
  }

  render() {

    return <div/>;
  }
}

export default connect(mapStateToProps, mapActionCreators)(LogoutView);
