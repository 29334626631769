import {List} from 'immutable';
import BaseModel from './BaseModel';
import {registerCustomValidators} from '../services/validators';
import Permission from '../services/Permission';

registerCustomValidators();

export enum UserRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}

const constraints = {
  id: {
    presence: true
  },
  gid: {
    presence: true
  }
};

const interestedKeys = ['displayName', 'givenName', 'domain', 'familyName', 'phone'];

const defaultValues = {
  gid: undefined,
  displayName: undefined,
  givenName: undefined,
  domain: undefined,
  familyName: undefined,
  phone: undefined,
  emails: List<String>(),
  permissions: List<Permission>(),
  disabled: false,
  authenticated: false,
  token: undefined,
  projectIds: List(),
  expectedHours: undefined,
  role: undefined
};

export default class User extends BaseModel(defaultValues, interestedKeys, constraints)<User> {

  gid: String;
  displayName: String;
  givenName: String;
  domain: String;
  familyName: String;
  phone: String;
  emails: List<String>;
  permissions: List<Permission>;
  disabled: boolean;
  authenticated: boolean;
  token: String;
  role: String;
  projectIds: List<number>;
  expectedHours: number;

  constructor(js?) {
    super(js);

    return this.setListArray(['emails', 'permissions', 'projectIds'], js) as User;
  }

  fromJS(js): User {

    return new User(js);
  }

  setAuthenticated(authenticated): User {

    return this.set('authenticated', authenticated) as User;
  }

  hasPermission(permission: Permission): boolean {

    return this.permissions.includes(permission);
  }

  hasOneOfPermissions(permissions: Array<Permission>): boolean {

    return !!permissions.find(permission => this.permissions.includes(permission));
  }
}
