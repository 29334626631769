import {routerActions} from 'react-router-redux';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history3/redirect';

export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => state.authenticatedUser.authenticated,
  authenticatingSelector: state => state.authenticatedUser.isLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated'
});
