import {createAction, handleActions} from 'redux-actions';
import App from '../models/App';
import {get} from './ActionsCommon';
import {navigate, pushState} from './Location';
import AuthenticatedUser from './AuthenticatedUser';
import {parse} from 'query-string';
import {getToken} from '../services/localStorage';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = new App();

let pingInterval;

// ------------------------------------
// Constants
// ------------------------------------
export const INITIALIZE_START = 'App.INITIALIZE_START';
export const INITIALIZE_COMPLETE = 'App.INITIALIZE_COMPLETE';
export const INITIALIZE_FAILED = 'App.INITIALIZE_FAILED';
export const CONNECTIVITY = 'App.CONNECTIVITY';
export const AVAILABLE_ROLES_RECEIVE = 'App.AVAILABLE_ROLES_RECEIVE';
export const RESET = 'App.RESET';
const UNAUTHORIZED = 401;
const DISCONNECTED = 0;
// ------------------------------------
// Actions
// ------------------------------------
export const startInitialize = createAction(INITIALIZE_START, (payload) => payload);
export const completeInitialize = createAction(INITIALIZE_COMPLETE, (payload) => payload);
export const failedInitialize = createAction(INITIALIZE_FAILED, (payload) => payload);
export const setConnectivity = createAction(CONNECTIVITY);
export const availableRolesReceiveAction = createAction(AVAILABLE_ROLES_RECEIVE);
export const resetAction = createAction(RESET);

export const initialize = () => {

  return (dispatch) => {

    dispatch(startInitialize());

    window.onpopstate = () => {
      dispatch(pushState(document.location.pathname));
    };

    const {pathname, search} = document.location;
    const query = parse(location.search);
    const redirect = query.redirect ? query.redirect : pathname + (search ? search : '');
    const token = getToken();

    if (!/^\/logout.*/.test(pathname) && token) {

      return dispatch(AuthenticatedUser.login(token, redirect))
        .then(() => dispatch(completeInitialize()));

    } else {

      return dispatch(completeInitialize());
    }
  };
};

export const teardown = () => {

  return () => {

    if (pingInterval) {

      clearInterval(pingInterval);
    }
  };
};

export function setupIntervals() {

  return (dispatch) => {

    dispatch(teardown());

    pingInterval = setInterval(function () {
      dispatch(pingAuthorization());
    }, 10000);
  };
}

export function pingAuthorization() {

  return (dispatch, getState) => {

    const {authenticatedUser} = getState();

    if (authenticatedUser && authenticatedUser.authenticated) {

      return dispatch(get('user', 'authenticated'))
        .then(() => dispatch(setConnectivity(true)));
    }
  };
}

export function handleGenericError(error) {

  return dispatch => {

    if (error.code === UNAUTHORIZED) {

      dispatch(AuthenticatedUser.resetAuthenticatedUser());
      dispatch(resetAction());
      dispatch(navigate('/'));
    }

    if (error.code === DISCONNECTED) {

      dispatch(setConnectivity(false));
    }

    throw error;
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const startOfInitialize = (state) => {

  return state
    .set('initializing', true)
    .set('initialized', false)
    .set('error', null);
};

const endOfInitialize = (state, error?) => {

  return state
    .set('initializing', false)
    .set('initialized', !error)
    .set('error', error);
};

const ACTION_HANDLERS = {
  [INITIALIZE_START]: (state) => startOfInitialize(state),
  [INITIALIZE_COMPLETE]: (state) => endOfInitialize(state),
  [INITIALIZE_FAILED]: (state, {payload}) => endOfInitialize(state, payload),
  [AVAILABLE_ROLES_RECEIVE]: (state, {payload}) => state.setAvailableRoles(payload),
  [CONNECTIVITY]: (state, {payload}) => state.setConnectivity(payload),
  [RESET]: () => endOfInitialize(initialState)
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
