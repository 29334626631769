/**
 * Created by Mauritz Untamala on 05/12/15.
 */

import * as React from 'react';
import {Component, PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import * as _ from 'lodash';
import {translate} from 'react-i18next';
import Input from './Input';
import {processedData} from './TableComponentUtil';

class TopSection extends Component<any, any> {

  static propTypes = {
    criteria: PropTypes.object,
    onFilterCriteriaChanged: PropTypes.func.isRequired,
    content: PropTypes.object
  };

  readonly debouncedCriteriaChanged;

  constructor(props) {
    super(props);
    this.state = {filter: props.criteria.filter};
    this.debouncedCriteriaChanged = _.debounce(this.props.onFilterCriteriaChanged, 500);
  }

  onFilterCriteriaChanged = (event) => {

    const value = event.target.value;
    this.setState({filter: value});
    this.debouncedCriteriaChanged(value);
  };

  render() {

    return (
      <div className='top-section'>
        <div className='simple-table-filter'>
          <div>
            <Input type='text' name='filter'
                   placeholder='Filter results'
                   value={this.state.filter || ''}
                   className='form-control'
                   onChange={this.onFilterCriteriaChanged}/>
          </div>
        </div>
        <div className='simple-table-top-content'>
          {this.props.content}
        </div>
      </div>
    );
  }
}

class Head extends Component<any, any> {

  static propTypes = {
    columns: PropTypes.array.isRequired,
    criteria: PropTypes.object,
    onSortCriteriaChanged: PropTypes.func.isRequired
  };

  getSortState = (field) => {

    if (this.props.criteria.columnName === field) {
      return this.props.criteria.sort === 'asc' ? 'desc' : 'asc';
    }

    return 'asc';
  };

  onClick = (columnName) => {

    this.props.onSortCriteriaChanged({
      columnName: columnName,
      sort: this.getSortState(columnName)
    });
  };

  column = (columnSettings) => {

    const columnName = columnSettings.columnName;
    const criteria = this.props.criteria;
    const sortState = criteria.columnName === columnName ? criteria.sort : undefined;

    let classes = classNames({
      'sort-ascending': sortState === 'asc',
      'sort-descending': sortState === 'desc',
      'sort-none': columnSettings.sortable !== false && !sortState
    });

    if (columnSettings.cssClassName) {
      classes = classNames(classes, columnSettings.cssClassName);
    }

    let onClick = null;

    if (columnSettings.sortable !== false) {
      onClick = this.onClick.bind(this, columnName);
    }

    return (
      <th key={columnName} className={classes} onClick={onClick}>
        <span>{columnSettings.displayName}</span><span className='sort-icon'></span>
      </th>
    );
  };

  columns = () => _.map(this.props.columns, this.column);

  render() {

    return (
      <thead>
      <tr>
        {this.columns()}
      </tr>
      </thead>
    );
  }
}

class TableRow extends Component<any, any> {

  static propTypes = {
    rowData: PropTypes.object.isRequired,
    rowKey: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    t: PropTypes.func.isRequired,
    customProps: PropTypes.object
  };

  column = (columnSettings) => {

    const data = _.get(this.props.rowData, columnSettings.columnName);
    const content = columnSettings.customComponent ?
      <columnSettings.customComponent {...this.props.customProps}
                                      key={`custom-${this.props.rowData.src[this.props.rowKey]}`}
                                      data={data}
                                      t={this.props.t}
                                      rowData={this.props.rowData}
                                      column={columnSettings.columnName}/> : data;

    const classes = columnSettings.cssClassName ? classNames(columnSettings.cssClassName) : null;

    return (
      <td key={columnSettings.columnName} className={classes}>
        {content}
      </td>
    );
  };

  columns = () => _.map(this.props.columns, this.column);

  onRowClick = () => {

    if (this.props.onRowClick) {
      this.props.onRowClick(this.props.rowData);
    }
  };

  render() {

    const {rowData} = this.props;

    return (
      <tr key={rowData.id || rowData._id} onClick={this.onRowClick}>
        {this.columns()}
      </tr>
    );
  }
}

@translate(['common'], {wait: true})
export default class TableComponent extends PureComponent<any, any> {

  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    initialFilter: PropTypes.string,
    initialSortColumn: PropTypes.string,
    initialSortState: PropTypes.string,
    onCriteriaChanged: PropTypes.func,
    rowKey: PropTypes.string,
    useFixedHeader: PropTypes.bool,
    onRowClick: PropTypes.func,
    topContent: PropTypes.object,
    loading: PropTypes.bool,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    t: PropTypes.func.isRequired,
    customProps: PropTypes.object
  };

  scroll;

  constructor(props) {
    super(props);
    this.state = {
      filter: props.initialFilter,
      columnName: props.initialSortColumn,
      sort: props.initialSortState,
      loading: props.loading
    };
  }

  componentDidMount() {

    this.setupInfiniteScrolling();
  }

  componentDidUpdate() {

    this.setupInfiniteScrolling();
  }

  componentWillUnmount() {

    this.detachScrollListener();
  }

  scrollListener = () => {

    const element = this.scroll;
    const scrollTop = (element && element.scrollTop) || document.body.scrollTop;
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight;
    const scrolledToBottom = (scrollTop + element.clientHeight) >= scrollHeight - 20;
    const {hasMore, loadMore} = this.props;

    if (this.hasScrollBar() && scrolledToBottom && hasMore && loadMore) {
      this.detachScrollListener();
      this.props.loadMore();
    }
  };

  hasScrollBar = () => {

    const element = this.scroll;
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight;
    const clientHeight = (element && element.clientHeight) || document.body.clientHeight;

    return scrollHeight > clientHeight;
  };

  scrollToTop = () => {

    const element = this.scroll;
    element.scrollTop = 0;
  };

  attachScrollListener = () => {

    if (!this.props.hasMore || !this.isInfiniteScroll()) {

      return;
    }

    if (this.scroll) {

      this.scroll.addEventListener('scroll', this.scrollListener);
      this.scroll.addEventListener('resize', this.scrollListener);
      this.scrollListener();
    }
  };

  setupInfiniteScrolling = () => {

    if (!this.isInfiniteScroll()) {

      return;
    }

    this.attachScrollListener();

    const {hasMore, loading, loadMore} = this.props;

    // Load as much data until view get's scroll bar if there is data to be loaded.
    if (hasMore && !this.hasScrollBar() && !loading) {

      loadMore();
    }
  };

  detachScrollListener = () => {

    if (!this.isInfiniteScroll()) {

      return;
    }

    if (this.scroll) {

      this.scroll.removeEventListener('scroll', this.scrollListener);
      this.scroll.removeEventListener('resize', this.scrollListener);
    }
  };

  row = (data) => {

    return (
      <TableRow key={data[this.props.rowKey] || data._id}
                rowData={data}
                t={this.props.t}
                rowKey={this.props.rowKey}
                customProps={this.props.customProps}
                columns={this.props.columns}
                onRowClick={this.props.onRowClick}/>
    );
  };

  rows = () => _.map(this.getData(), this.row, undefined);

  isInfiniteScroll = () => !!this.props.loadMore;

  getData = () => {

    if (this.isInfiniteScroll()) {

      return this.props.data;

    } else {

      return processedData(this.state, this.props.data, this.props.columns);
    }
  };

  onCriteriaChanged = (criteria) => {

    this.setState(criteria, () => {

      if (this.props.onCriteriaChanged) {

        this.scrollToTop();
        this.props.onCriteriaChanged(this.state);
      }
    });
  };

  onFilterCriteriaChanged = (filter) => this.onCriteriaChanged({filter});

  getHead = () => {

    return (
      <Head columns={this.props.columns}
            criteria={this.state}
            onSortCriteriaChanged={this.onCriteriaChanged}/>
    );
  };

  getFixedHeader = (apply) => {

    if (!apply) return;

    return <table>{this.getHead()}</table>;
  };

  getNormalHeader = (apply) => {

    if (!apply) return;

    return this.getHead();
  };

  getTopSection = () => {

    if (this.props.showFilter) {

      return (
        <TopSection criteria={this.state}
                    onFilterCriteriaChanged={this.onFilterCriteriaChanged}
                    content={this.props.topContent}/>
      );
    }
  };

  loadingRow = () => {

    if (this.props.loading) {

      return (
        <tr>
          <td colSpan={this.props.columns.length} className='loading'><span>{this.props.t('loading')}</span></td>
        </tr>
      );
    }
  };

  render() {

    return (
      <div className='simple-table'>
        {this.getTopSection()}
        <div className='simple-table-container'>
          <div className='simple-table-body'>
            <div className='simple-table-wrapper'>
              {this.getFixedHeader(this.props.useFixedHeader)}
              <div className='simple-table-row-wrapper' ref={scroll => this.scroll = scroll}>
                <table className='simple-table-rows'>
                  {this.getNormalHeader(!this.props.useFixedHeader)}
                  <tbody>
                  {this.rows()}
                  {this.loadingRow()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
