import * as React from 'react';
import NavigationView from '../../components/NavigationView';

class NavigationLayout extends React.Component<any, any> {

  render() {

    const {children, ...rest} = this.props;

    return (
      <NavigationView key='navigation-view' {...rest}>
        {children}
      </NavigationView>
    );
  }
}

export default NavigationLayout;
