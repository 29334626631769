/**
 * Created by Mauritz Untamala on 27/08/15.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import Users from '../modules/Users';
import Select from './Select';

const mapStateToProps = ({users}, _ownProps) => ({users});

const mapActionsToProps = {
  getUsers: Users.getModels
};

class UserSelect extends PureComponent<any, any> {

  static propTypes = {
    getUsers: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props, 'users');
  }

  componentDidMount() {

    this.props.getUsers();
  }

  modelToOption = model => ({
    label: model.displayName,
    value: model.id,
    disabled: !!model.disabled
  });

  render() {

    return (
      <Select key='users_select'
              name='form-field-name'
              models={this.props.users.list}
              selectedOptions={this.props.selectedOptions}
              isMulti={true}
              modelToOption={this.modelToOption}
              onChange={this.props.onChange}
              disabled={this.props.readOnly}/>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UserSelect);
