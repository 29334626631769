/**
 * Created by Mauritz Untamala on 01/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import I18n from './I18n';
import {PropTypes} from 'prop-types';
import {createMonthRange, createFromDateToNowRange} from '../util';
import HourBar from '../components/HourBar/HourBar';

export default class WeekDetails extends Component<any, any> {

  static readonly defaultExpectedHours = {expectedHours: 0, accumulatedHours: 0};

  static propTypes = {
    selectedWeek: PropTypes.object.isRequired,
    totalHours: PropTypes.number.isRequired,
    expectedHours: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  renderDetail = (keyValue, label, value) => {

    return (
      <div className='detail' key={keyValue}>
        <div className='detail-label'>{label}</div>
        <div className='detail-value'>{value}</div>
      </div>
    );
  };

  renderExpectedHoursDetail = (
    keyValue,
    titleComponent,
    {accumulatedHours, expectedHours} = WeekDetails.defaultExpectedHours,
    expectedHoursFromBeginDateToNow) => {

    const valueComponent = (
      <div>
        <span>{accumulatedHours} / {expectedHours}</span>
      </div>
    );

    let actualHourPercentage = 0;
    let expectedHourPercentage = 0;

    if (expectedHours && accumulatedHours !== undefined && expectedHoursFromBeginDateToNow && expectedHoursFromBeginDateToNow.expectedHours) {
      actualHourPercentage = accumulatedHours / expectedHours;
      expectedHourPercentage = expectedHoursFromBeginDateToNow.expectedHours / expectedHours;
    }

    return (
      <div className='expected_hour' key={keyValue}>
        <div className='expected_hour__detail'>
          {this.renderDetail(keyValue, titleComponent, valueComponent)}
        </div>
        <div className='expected_hour__bar'>
          <HourBar
            expectedHourPercentage={expectedHourPercentage}
            actualHourPercentage={actualHourPercentage}/>
        </div>
      </div>
    );
  };

  renderExpectedHoursMonthDetail = (keyValue, month) => {

    const monthRange = createMonthRange(month);
    const expectedHoursMonthRange = this.props.expectedHours.getResult(monthRange);
    const beginMonthToNowRange = createFromDateToNowRange(month, 'month');
    const expectedHoursFromBeginMonthToNow = this.props.expectedHours.getResult(beginMonthToNowRange);

    return this.renderExpectedHoursDetail(
      keyValue,
      <I18n i18nKey='hourReporting.expectedHoursMonth' args={{month: monthRange.start.format('MMMM')}}/>,
      expectedHoursMonthRange,
      expectedHoursFromBeginMonthToNow
    );
  };

  renderExpectedHours = () => {

    const {selectedWeek, expectedHours} = this.props;
    const {start, end} = selectedWeek;
    const details = [];

    const beginWeekToNowRange = createFromDateToNowRange(start, 'week');
    const expectedHoursFromBeginWeekToNow = this.props.expectedHours.getResult(beginWeekToNowRange);

    details.push(this.renderExpectedHoursDetail(
      'weekHours',
      <I18n i18nKey='hourReporting.expectedHoursWeek' args={{week: start.isoWeek()}}/>,
      expectedHours.getResult(selectedWeek),
      expectedHoursFromBeginWeekToNow
    ));

    details.push(this.renderExpectedHoursMonthDetail('startMonthHours', start));

    if (start.year() < end.year() || start.month() < end.month()) {
      details.push(this.renderExpectedHoursMonthDetail('endMonthHours', end));
    }

    return details;
  };

  render() {

    return (
      <div className='week-details'>
        {this.renderExpectedHours()}
      </div>
    );
  }
}
