/**
 * Created by Mauritz Untamala on 08/11/15.
 */
import * as React from 'react';
import {Component} from 'react';
import DatePicker from 'react-date-picker';
import {PropTypes} from 'prop-types';
import I18n from '../services/I18n';
import moment from 'moment-es6';

export default class RangeDatePicker extends Component<any, any> {

  static propTypes = {
    from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func.isRequired
  };

  /* Calendar type can be US or ISO 8601 */
  private static readonly calendarType = 'ISO 8601';

  constructor(props) {
    super(props);
  }

  onHandleFromChange = (date) => {

    if (date && moment(date).isAfter(this.props.to)) {

      this.props.onChange(date, date);

    } else {

      this.props.onChange(date, this.props.to);
    }
  }

  onHandleToChange = (date) => {

    if (date && moment(date).isBefore(this.props.from)) {

      this.props.onChange(date, date);

    } else {

      this.props.onChange(this.props.from, date);
    }
  }

  render() {

    const {from, to} = this.props;

    return (
      <div className='range-container'>
        <DatePicker calendarType={RangeDatePicker.calendarType}
                    locale={I18n.language}
                    value={from}
                    onChange={this.onHandleFromChange}
                    showWeekNumbers={true}/>
        <span className='range-divider'>-</span>
        <DatePicker calendarType={RangeDatePicker.calendarType}
                    locale={I18n.language}
                    value={to}
                    minDate={from}
                    onChange={this.onHandleToChange}
                    showWeekNumbers={true}/>
      </div>
    );
  }
}
