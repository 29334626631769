import BaseModel from './BaseModel';
import {MomentRange} from 'moment-range';
import {Map} from 'immutable';

const defaultValues = {
  expectedHours: Map<string, ExpectedHoursResult>()
};

interface ExpectedHoursResult {
  expectedHours: number;
  accumulatedHours: number;
}

export default class ExpectedHours extends BaseModel(defaultValues)<ExpectedHours> {

  expectedHours: Map<string, ExpectedHoursResult>;

  constructor(js?) {
    super(js);
  }

  fromJS(_js): ExpectedHours {

    return new ExpectedHours();
  }

  addResult(range: MomentRange, result: ExpectedHoursResult) {

    return this.set('expectedHours', this.expectedHours.set(range.toString(), result));
  }

  getResult(range: MomentRange): ExpectedHoursResult {

    return this.expectedHours.get(range.toString());
  }
}
