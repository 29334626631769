import Users from '../models/Users';
import ModelsModule from './ModelsModule';
import User from './User';

const initialState = new Users();

class UsersModule extends ModelsModule {

  constructor() {
    super('user', initialState);
  }

  protected getAdditionalActionHandlers() {

    return {
      [User.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [User.getUpdateModelRequestType()]: (state, {payload}) => state.addModel(payload.startOfSaving()),
      [User.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [User.getUpdateModelFailedType()]: (state, {payload: {error, model}}) => {

        if (model) {
          return state.addModel(model.endOfSaving(error));
        }

        return state;
      },
      [User.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new UsersModule();
