import * as React from 'react';
import './HourBar.less';

interface Props {
  expectedHourPercentage: number;
  actualHourPercentage: number;
}

class HourBar extends React.Component<Props, any> {

  getBarStyle = (percentage) => {

    return {width: `${percentage * 100}%`};
  }

  render() {

    const {actualHourPercentage, expectedHourPercentage} = this.props;

    return (
      <div className='hour-bar'>
        <div className='hour-bar__red'  style={this.getBarStyle(expectedHourPercentage)}/>
        <div className='hour-bar__yellow' style={this.getBarStyle(actualHourPercentage)}/>
      </div>
    );
  }
}

export default HourBar;
