/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';
import * as classNames from 'classnames';
import {translate} from 'react-i18next';
import * as _ from 'lodash';

@translate(['common'], {wait: false, withRef: true})
class InputComponent extends Component<any, any> {

  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    forceShowError: PropTypes.bool
  };

  textInput: any;

  constructor(props) {
    super(props);
    this.state = {showError: this.props.forceShowError};
  }

  componentDidUpdate(prevProps) {

    const {forceShowError} = this.props;

    if (prevProps.forceShowError !== forceShowError) {
      this.setState({forceShowError, showError: forceShowError});
    }
  }

  onBlur = () => this.setState({showError: true});

  onFocus = () => this.setState({showError: !!this.props.forceShowError});

  focus = () => this.textInput.focus();

  shouldShowError = () => this.props.error && (this.state.showError || !_.isEmpty(this.props.value));

  getError = () => {

    if (this.shouldShowError()) {
      return <div className='error'>{this.props.t(this.props.error)}</div>;
    }
  };

  getLabel = (label, labelClassName) => {
    if (label) {
      return <ControlLabel bsClass={`control-label ${labelClassName}`}>{label}</ControlLabel>;
    }
  };

  render() {

    const formGroupClasses = classNames({
      'form-group': true,
      'has-error': this.shouldShowError()
    });

    const {
      label,
      labelClassName,
      error,
      onChange,
      value,
      disabled,
      wrapperClassName,
      type,
      i18nLoadedAt,
      t,
      i18n,
      groupClassName,
      ...rest
    } = this.props;
    const groupClassNameProp = groupClassName ? formGroupClasses + ' ' + groupClassName : formGroupClasses;
    const help = this.getError();

    return (
      <FormGroup bsClass={groupClassNameProp} type={'text'}>
        {this.getLabel(label, labelClassName)}
        <div className={wrapperClassName}>
          <FormControl inputRef={(input) => {
            this.textInput = input;
          }}
                       componentClass={type === 'textarea' ? 'textarea' : undefined}
                       onBlur={this.onBlur}
                       onFocus={this.onFocus}
                       onChange={onChange}
                       disabled={disabled}
                       value={value || ''}
                       type={type === 'textarea' ? undefined : type}
                       {...rest} />
          {help && <HelpBlock>{help}</HelpBlock>}
        </div>
      </FormGroup>
    );
  }
}

export default InputComponent;
