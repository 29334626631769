import HourReport from './HourReport';
import Collection from './ImmutableCollection';

export default class HourReports extends Collection(HourReport)<HourReport> {

  getHourReportsForDate(date) {

    return this.list.filter(report => date.diff(report.date, 'days') === 0);
  }

  getTotalHoursForRange(range) {

    return this.list.filter(report => range.contains(report.date))
      .map(report => report.hours)
      .reduce((accu, value) => accu + value, 0);
  }
}
