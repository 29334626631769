/**
 * Created by Mauritz Untamala on 8/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import StatusColumn from '../../components/StatusColumn';
import Select from '../../components/Select';
import ProjectActionColumn from '../../components/ProjectActionColumn';
import * as PropTypes from 'prop-types';
import {navigate} from '../../modules/Location';
import Customers from '../../modules/Customers';
import Projects from '../../modules/Projects';
import Project from '../../modules/Project';
import ProjectModel from '../../models/Project';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import BaseTableView from '../../components/BaseTableView';
import NameColumn from '../../components/NameColumn';
import DisabledColumn from '../../components/DisabledColumn';
import Permission from '../../services/Permission';
import ToggleButton from '../../components/ToggleButton';
import Criteria from '../../models/Criteria';

class CustomerColumn extends Component<any, any> {

  static propTypes = {
    saveModel: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
  };

  private input;

  constructor(props) {
    super(props);

    this.state = {
      editing: false
    };
  }

  componentDidUpdate(_prevProps, prevState) {

    if (!prevState.editing && this.state.editing && this.input) {

      this.input.focus();
    }
  }

  setEditing = () => this.setState({editing: true && !this.props.readOnly});

  onCustomerChange = (selectedId) => {

    const {saveModel, customers, rowData} = this.props;
    const customer = customers.getModelById(selectedId);
    const project = rowData.src
      .set('customerId', customer.id)
      .set('customerName', customer.name);

    this.setState({editing: false}, () => saveModel(project));
  };

  getCustomerOptions = () => {

    const {customers} = this.props;

    return customers.list.map(customer => ({
      value: customer.id,
      label: customer.name,
      disabled: !!customer.disabled
    })).toArray();
  };

  onBlur = () => this.setState({editing: false});

  render() {

    const {rowData, customers} = this.props;

    if (rowData.id && !this.state.editing) {

      return <div onClick={this.setEditing}>{rowData.customerName}</div>;

    } else {

      return (
        <Select
          ref={input => this.input = input}
          id={'customer_' + rowData.src._id}
          key='customer'
          name='customer'
          className='customer'
          onBlur={this.onBlur}
          selectedOptions={rowData.customerId}
          models={customers.list}
          onChange={this.onCustomerChange}
        />
      );
    }
  }
}

const mapStateToProps = ({customers, projects, project, authenticatedUser, tasks}, _ownProps) => {

  const readOnly = !authenticatedUser.hasPermission(Permission.WriteProject);

  const criteria = Criteria.getCriteriaWithoutInitialDateRage(_ownProps.location);

  return {
    criteria,
    customers,
    models: projects,
    model: project,
    readOnly,
    authenticatedUser,
    tasks
  };
};

const mapActionsToProps = {
  updateCriteria: Projects.updateCriteria,
  fetchCustomers: Customers.getModels,
  getModels: Projects.getModels,
  saveModel: Project.saveModelDebounced,
  deleteModel: Project.deleteModel,
  navigate
};

@translate(['common'], {wait: true})
class ProjectsView extends BaseTableView {

  static propTypes = {
    updateCriteria: PropTypes.func.isRequired,
    criteria: PropTypes.object.isRequired,
    fetchCustomers: PropTypes.func.isRequired,
    getModels: PropTypes.func.isRequired,
    saveModel: PropTypes.func.isRequired,
    customers: PropTypes.object.isRequired,
    models: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props, 'projects');
  }

  componentDidMount() {

    super.componentDidMount();
    const {fetchCustomers} = this.props;

    fetchCustomers();
  }

  getColumnMetadata() {

    const {t, readOnly} = this.props;

    const columns = [
      {
        'columnName': 'name',
        'cssClassName': 'name-column',
        'displayName': t('column.project'),
        'customComponent': NameColumn
      },
      {
        'columnName': 'customerName',
        'cssClassName': 'customer-column',
        'displayName': t('column.customer'),
        'customComponent': CustomerColumn
      },
      {
        'columnName': 'disabled',
        'cssClassName': 'disabled-column',
        'displayName': t('column.disabled'),
        'customComponent': DisabledColumn,
        'filterValue': data => t('' + !data),
        'sortValue': data => t('' + !data)
      },
      {
        'columnName': 'actions',
        'displayName': t('column.actions'),
        'cssClassName': 'actions-column',
        'customComponent': ProjectActionColumn,
        'sortable': false
      }
    ] as Array<any>;

    if (!readOnly) {

      columns.push({
        'columnName': 'isSaving',
        'displayName': '',
        'cssClassName': 'status-column',
        'customComponent': StatusColumn,
        'sortable': false
      });
    }

    return columns;
  }

  createEmptyModel() {
    const {tasks, authenticatedUser} = this.props;
    const defaultTaskIds = tasks.getDefaultTasks().map(task => task.id);
    return new ProjectModel({
      editing: true,
      userIds: [authenticatedUser.id],
      taskIds: defaultTaskIds
    });
  }

  handleActiveToggle = () => {

    const {criteria} = this.props;

    this.onCriteriaChange(criteria.set('showDisabled', !criteria.showDisabled));
  }

  getTopContent() {

    const {showDisabled} = this.props.criteria;

    return <ToggleButton
      checked={!showDisabled}
      label={this.props.t('showActivesLabel')}
      onChange={this.handleActiveToggle}/>;
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectsView);
