import Task from '../models/Task';
import ModelModule from './ModelModule';
import {remove} from './ActionsCommon';
import * as _ from 'lodash';

const initialState = new Task();

export class TaskModule extends ModelModule<Task> {

  constructor() {
    super('task', initialState);
  }

  protected deleteSuccessMessageProps(model) {

    return {name: model.name};
  }

  deleteModel(model) {
    return (dispatch) => {

      dispatch(this.deleteModelRequestAction());

      return dispatch(remove(this.path, model.id))
        .then((res) => {
          const updatedTask = _.first(res);
          // Delete task API endpoint return OK if task is successfully deleted,
          // otherwise returns updated task if the task is used in any hour reports
          return _.isObject(updatedTask)
                  ? dispatch(this.updateModelSuccessAction(updatedTask))
                  : dispatch(this.deleteModelSuccessAction(model));
        })
        .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error, model)));

    };
  }
}

export default new TaskModule();
