import * as React from 'react';
import {PureComponent} from 'react';
import Select, { components } from 'react-select';
import * as _ from 'lodash';

const MultiValueRemove = (props) => {
  const {data: {disabled}} = props;
  // Hide 'x' button if data is disabled
  return !disabled ? <components.MultiValueRemove {...props}/> : null;
};

export default class SelectWrapper extends PureComponent<any, any> {

  private static readonly disabledOptionColor = '#ccc';

  private input;

  private static readonly customTheme = (theme) => ({
    ...theme,
    spacing: {
      baseUnit: 2,
      controlHeight: 32
    },
    colors: {
      ...theme.colors,
      primary: '#FFB81C' // makeYellow
    }
  });

  onChange = (selectedOptions) => {

    const {onChange} = this.props;
    const selectedIds = _.isArray(selectedOptions) ? _.map(selectedOptions, 'value') : selectedOptions.value;

    onChange(selectedIds);
  };

  modelToOption = model => {

    if (this.props.modelToOption) {

      return this.props.modelToOption(model);
    }

    return {
      label: model.name,
      value: model.id,
      disabled: !!model.disabled
    };
  };

  getOptions = (models) => {

    return models
      .map(this.modelToOption)
      .sort((a, b) => {

        if (!a.disabled && b.disabled) {
          return -1;
        }

        if (a.disabled && !b.disabled) {
          return 1;
        }

        return _.toString(a.label).localeCompare(_.toString(b.label));
      })
      .toArray();
  };

  getSelectedOptions = (options) => {

    const {selectedOptions} = this.props;

    return (_.isArray(selectedOptions) ? selectedOptions : [selectedOptions])
      .map(id => options.find(t => t.value === id))
      .filter(task => task);
  };

  focus = () => this.input.focus();

  getDisabledOptionColor = (data) => data.disabled ? SelectWrapper.disabledOptionColor : null;

  getDisabledOptionColorStyles = (styles, {data}) => ({
    ...styles,
    color: this.getDisabledOptionColor(data)
  });

  getStyles = () => {

    return {
      option: this.getDisabledOptionColorStyles,
      multiValue: (styles, {data}) => ({
        ...styles,
        backgroundColor: this.getDisabledOptionColor(data)
      }),
      multiValueLabel: this.getDisabledOptionColorStyles,
      multiValueRemove: this.getDisabledOptionColorStyles
    };
  };

  render() {

    const {className, isMulti, models, options, onChange, isClearable, allowRemoveDisabledItem, ...remaining} = this.props;
    const resolvedOptions = options || this.getOptions(models);
    const selectedOptions = this.getSelectedOptions(resolvedOptions);

    const customRemoveComponent = (allowRemoveDisabledItem === false) ? { MultiValueRemove } : undefined;
    return (
      <Select ref={ref => this.input = ref}
              components={customRemoveComponent}
              theme={SelectWrapper.customTheme}
              className={'react-select-container' + (className ? ' ' + className : '')}
              classNamePrefix='react-select'
              isOptionDisabled={(({disabled}) => disabled)}
              isClearable={isClearable || false}
              isMulti={isMulti || false}
              closeMenuOnSelect={!isMulti}
              options={resolvedOptions}
              value={selectedOptions}
              onChange={this.onChange}
              styles={this.getStyles()}
              {...remaining}/>
    );
  }
}
