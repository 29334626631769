import i18n from '../services/I18n';
import {NotificationManager} from 'react-notifications';

export function infoKey(messageKey, args?) {
  return info(i18n.t(messageKey, args));
}

export function info(message) {
  return NotificationManager.info(message, i18n.t('info'));
}

export function successKey(messageKey, args?) {
  return success(i18n.t(messageKey, args));
}

export function success(message) {
  return NotificationManager.success(message, i18n.t('success'));
}

export function warningKey(messageKey, args?) {
  return warning(i18n.t(messageKey, args));
}

export function warning(message) {
  return NotificationManager.warning(message, i18n.t('warning'));
}

export function errorKey(messageKey, args) {
  return error(i18n.t(messageKey, args));
}

export function error(message) {
  return NotificationManager.error(message, i18n.t('error'));
}

export default NotificationManager;
