/**
 * Created by Mauritz Untamala on 27/09/15.
 */

import * as http from './http';
import * as _ from 'lodash';
import Config from '../config/index';

function addInternalId(object) {
  return data => _.isArray(data.data) ? data.data :  _.merge(data.data, {_id: object._id});
}

export function update(path, object, token?) {

  return http.put(Config.API_URL + path, object, token)
    .then(addInternalId(object));
}

export function add(path, object, token?) {

  return http.post(Config.API_URL + path, object, token)
    .then(addInternalId(object));
}

export function get(path, token?) {

  return http.get(Config.API_URL + path, undefined, token)
    .then(data => data.data);
}

export function remove(path, token?) {

  return http.del(Config.API_URL + path, undefined, token)
    .then(data => data.data);
}
