import BaseModel from './BaseModel';
import moment from 'moment-es6';

const defaultValues = {
  customerName: undefined,
  customerId: undefined,
  projectName: undefined,
  projectId: undefined,
  userName: undefined,
  userId: undefined,
  taskName: undefined,
  taskId: undefined,
  date: undefined,
  hours: undefined,
  description: undefined,
  invoiced: undefined,
  selected: false,
  ids: undefined
};

export default class ReportingReport extends BaseModel(defaultValues)<ReportingReport> {

  hours: number;

  constructor(js?) {
    super(js);

    return this.set('date', moment(js && js.date)) as ReportingReport;
  }

  fromJS(js): ReportingReport {

    return new ReportingReport(js);
  }
}
