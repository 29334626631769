import {createAction} from 'redux-actions';
import ModelModule from './ModelModule';
import User from '../models/User';
import {setConnectivity, setupIntervals, teardown} from './App';
import {navigate} from './Location';
import {bind} from '../util';
import {removeToken, saveToken} from '../services/localStorage';
import {add} from './ActionsCommon';
import * as jwtDecode from 'jwt-decode';

const initialState = new User();

export class AuthenticatedUserModule extends ModelModule<User> {

  private loginRequestType: string;
  private loginSuccessType: string;
  private loginFailedType: string;
  private logoutRequestType: string;
  private logoutSuccessType: string;
  private authenticationRequestType: string;
  private authenticationSuccessType: string;
  private authenticationFailedType: string;
  private loginRequestAction: any;
  private loginSuccessAction: any;
  private loginFailedAction: any;
  private logoutRequestAction: any;
  private logoutSuccessAction: any;

  constructor() {
    super('profile', initialState, {reducerKey: 'authenticatedUser'});
    bind(this, this.login, this.logout);
  }

  public login(jwtToken, redirect?) {

    return (dispatch) => {

      dispatch(this.loginRequestAction());

      return dispatch(add('auth', 'login', null, {jwtToken}))
        .then(({token}) => {

          saveToken(token);

          const decoded = jwtDecode(token);
          decoded.token = token;

          dispatch(this.loginSuccessAction(decoded));

          return dispatch(this.postAuthenticateSuccess(redirect));
        })
        .catch(error => {

          removeToken();

          dispatch(this.loginFailedAction(error));

          return dispatch(navigate('/login'));
        });
    };
  }

  public logout() {

    return (dispatch) => {

      dispatch(this.logoutRequestAction());

      return Promise.resolve(removeToken())
        .then(() => {

          dispatch(teardown());
          dispatch(this.logoutSuccessAction());
          return dispatch(navigate('/'));
        })
        .catch(error => dispatch(this.loginFailedAction(error)));
    };
  }

  public resetAuthenticatedUser() {

    return dispatch => dispatch(this.logoutSuccessAction());
  }

  public getLogoutSuccessType() {
    return this.logoutSuccessType;
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {

    const setAuthenticatedUser = (state: User, {payload}) => state.fromJS(payload).setAuthenticated(true);

    return {
      [this.authenticationRequestType]: (state) => state.startOfLoading(),
      [this.authenticationSuccessType]: setAuthenticatedUser,
      [this.authenticationFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.loginRequestType]: (state) => state.startOfLoading(),
      [this.loginSuccessType]: setAuthenticatedUser,
      [this.loginFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.logoutRequestType]: (state) => state.startOfLoading(),
      [this.logoutSuccessType]: () => initialState,
      [this.getModelSuccessType]: setAuthenticatedUser,
      [this.updateModelSuccessType]: setAuthenticatedUser
    };
  }

  protected initializeTypes() {
    super.initializeTypes();

    this.loginRequestType = `${this.name}.LOGIN_REQUEST`;
    this.loginSuccessType = `${this.name}.LOGIN_SUCCESS`;
    this.loginFailedType = `${this.name}.LOGIN_FAILURE`;
    this.logoutRequestType = `${this.name}.LOGOUT_REQUEST`;
    this.logoutSuccessType = `${this.name}.LOGOUT_SUCCESS`;
  }

  protected initializeActions() {
    super.initializeActions();

    this.loginRequestAction = createAction(this.loginRequestType);
    this.loginSuccessAction = createAction(this.loginSuccessType);
    this.loginFailedAction = createAction(this.loginFailedType);
    this.logoutRequestAction = createAction(this.logoutRequestType);
    this.logoutSuccessAction = createAction(this.logoutSuccessType);
  }

  private postAuthenticateSuccess(redirect?) {

    return (dispatch) => {

      dispatch(setConnectivity(true));
      dispatch(setupIntervals());

      if (redirect) {

        return dispatch(navigate(redirect));

      } else {

        return dispatch(navigate('/hour-report'));
      }
    };
  }
}

export default new AuthenticatedUserModule();
