import {combineReducers} from 'redux-immutable';
import authenticatedUser from '../modules/AuthenticatedUser';
import app from '../modules/App';
import pagination from '../modules/Pagination';
import reports from '../modules/Reports';
import routing from '../modules/Routing';
import user from '../modules/User';
import users from '../modules/Users';
import customer from '../modules/Customer';
import customers from '../modules/Customers';
import hourReport from '../modules/HourReport';
import hourReports from '../modules/HourReports';
import project from '../modules/Project';
import projects from '../modules/Projects';
import customerProjects from '../modules/CustomerProjects';
import userProjects from '../modules/UserProjects';
import task from '../modules/Task';
import tasks from '../modules/Tasks';
import location from '../modules/Location';
import expectedHours from '../modules/ExpectedHours';

export const makeRootReducer = (asyncReducers?: any) => {
  return combineReducers({
    app,
    authenticatedUser: authenticatedUser.getReducer(),
    customer: customer.getReducer(),
    customers: customers.getReducer(),
    hourReport: hourReport.getReducer(),
    hourReports: hourReports.getReducer(),
    location,
    pagination,
    project: project.getReducer(),
    projects: projects.getReducer(),
    customerProjects: customerProjects.getReducer(),
    userProjects: userProjects.getReducer(),
    reports: reports.getReducer(),
    routing,
    task: task.getReducer(),
    tasks: tasks.getReducer(),
    user: user.getReducer(),
    users: users.getReducer(),
    expectedHours: expectedHours.getReducer(),
    ...asyncReducers
  });
};

export default makeRootReducer;
