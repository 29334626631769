import * as React from 'react';
import {Component} from 'react';
import StatusColumn from '../../components/StatusColumn';
import Button from '../../components/ConfirmButton';
import * as PropTypes from 'prop-types';
import {navigate} from '../../modules/Location';
import {connect} from 'react-redux';
import Tasks from '../../modules/Tasks';
import Task from '../../modules/Task';
import TaskModel from '../../models/Task';
import {translate} from 'react-i18next';
import NameColumn from '../../components/NameColumn';
import DisabledColumn from '../../components/DisabledColumn';
import DefaultToggleColumn from '../../components/DefaultToggleColumn';
import BaseTableView from '../../components/BaseTableView';
import Permission from '../../services/Permission';
import ToggleButton from '../../components/ToggleButton';
import Criteria from '../../models/Criteria';

class ActionsColumn extends Component<any, any> {

  static propTypes = {
    deleteModel: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  deleteModel = () => this.props.deleteModel(this.props.rowData.src);

  getActions = () => {

    const {rowData} = this.props;
    const actions = [];

    if (rowData.id) {
      actions.push(
        <Button key={'remove_' + rowData.id}
                className='btn btn-default'
                onClick={this.deleteModel}>
          <i className='fa fa-trash fa-lg'/>
        </Button>
      );
    }

    return actions;
  };

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>;
  }
}

const mapStateToProps = ({tasks, task, authenticatedUser}, _ownProps) => {

  const readOnly = !authenticatedUser.hasPermission(Permission.WriteTask);

  const criteria = Criteria.getCriteriaWithoutInitialDateRage(_ownProps.location);

  return {
    models: tasks,
    model: task,
    criteria,
    readOnly
  };
};

const mapActionsToProps = {
  updateCriteria: Tasks.updateCriteria,
  getModels: Tasks.getModels,
  saveModel: Task.saveModelDebounced,
  deleteModel: Task.deleteModel,
  navigate
};

@translate(['common'], {wait: true})
class TasksView extends BaseTableView {

  constructor(props) {
    super(props, 'tasks');
  }

  getColumnMetadata() {

    const {t, readOnly} = this.props;

    const columns = [{
      'columnName': 'name',
      'displayName': t('column.customer'),
      'cssClassName': 'name-column',
      'customComponent': NameColumn
    }, {
      'columnName': 'disabled',
      'displayName': t('column.disabled'),
      'cssClassName': 'disabled-column',
      'customComponent': DisabledColumn,
      'filterValue': value => t('' + !value),
      'sortValue': value => t('' + !value)
    }, {
      'columnName': 'default',
      'displayName': t('column.default'),
      'cssClassName': 'disabled-column',
      'customComponent': DefaultToggleColumn,
      'filterValue': value => t('' + !value),
      'sortValue': value => t('' + !value)
    }] as Array<any>;

    if (!readOnly) {

      columns.push({
          'columnName': 'actions',
          'displayName': t('column.actions'),
          'cssClassName': 'actions-column',
          'customComponent': ActionsColumn,
          'sortable': false
        }, {
          'columnName': 'isSaving',
          'displayName': '',
          'cssClassName': 'status-column',
          'customComponent': StatusColumn,
          'sortable': false
        }
      );
    }

    return columns;
  }

  createEmptyModel() {
    return new TaskModel({editing: true});
  }

  handleActiveToggle = () => {

    const {criteria} = this.props;

    this.onCriteriaChange(criteria.set('showDisabled', !criteria.showDisabled));
  }

  getTopContent() {

    const {showDisabled} = this.props.criteria;

    return <ToggleButton
      checked={!showDisabled}
      label={this.props.t('showActivesLabel')}
      onChange={this.handleActiveToggle}/>;
  }
}

export default connect(mapStateToProps, mapActionsToProps)(TasksView);
