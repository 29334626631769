import BaseModel from './BaseModel';
import {registerCustomValidators} from '../services/validators';

registerCustomValidators();

const constraints = {
  name: {
    presence: true
  }
};

const interestedKeys = [
  'name',
  'disabled'
];

const defaultValues = {
  uid: undefined,
  name: undefined,
  disabled: undefined,
  authenticated: false
};

export default class Customer extends BaseModel(defaultValues, interestedKeys, constraints)<Customer> {

  uid: String;
  name: String;
  disabled: boolean;
  authenticated: boolean;

  constructor(js?) {
    super(js);
  }

  fromJS(js): Customer {

    return new Customer(js);
  }
}
