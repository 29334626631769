/**
 * Created by Mauritz Untamala on 27/08/15.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import I18n from '../../components/I18n';
import WeekPicker from '../../components/WeekPicker';
import HourReportingDay from '../../components/HourReportingDay';
import WeekDetails from '../../components/WeekDetails';
import {DATE_SHORT_FORMAT} from '../../config/constants';
import {connect} from 'react-redux';
import Tasks from '../../modules/Tasks';
import {createWeekRange, getDaysForRange} from '../../util';
import HourReports from '../../modules/HourReports';
import HourReport from '../../modules/HourReport';
import UserProjects from '../../modules/UserProjects';
import Projects from '../../modules/Projects';
import ExpectedHours from '../../modules/ExpectedHours';
import {translate} from 'react-i18next';
import * as Moment from 'moment';
import CriteriaModel from '../../models/Criteria';
import {extendMoment} from 'moment-range';
import Criteria from '../../models/Criteria';

const moment = extendMoment(Moment);

const getCriteria = ({query}) => {

  if (query.from && query.to) {

    return CriteriaModel.fromQuery(query);

  } else {

    const {start, end} = createWeekRange(moment());

    return CriteriaModel.fromQuery({from: start, to: end});
  }
};

const mapStateToProps = ({authenticatedUser, projects, userProjects, tasks, hourReport, hourReports, expectedHours}, ownProps) => {

  return {
    criteria: getCriteria(ownProps.location),
    authenticatedUser,
    hourReport,
    hourReports,
    projects,
    userProjects,
    tasks,
    expectedHours
  };
};

const mapActionsToProps = {
  getUserProjects: UserProjects.fetchUserProjects,
  getProjects: Projects.getModels,
  getTasks: Tasks.getModels,
  updateCriteria: HourReports.updateCriteria,
  saveModel: HourReport.saveModelDebounced,
  deleteModel: HourReport.deleteModel,
  getExpectedHoursForMonth: ExpectedHours.getExpectedHoursForMonth,
  getExpectedHoursForWeek: ExpectedHours.getExpectedHoursForWeek,
  getExpectedHoursFromStartMonthToNow: ExpectedHours.getExpectedHoursFromStartMonthToNow,
  getExpectedHoursFromStartWeekToNow: ExpectedHours.getExpectedHoursFromStartWeekToNow
};

enum CalendarButton {
  NEXT_WEEK = 'NEXT_WEEK',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK'
}

@translate(['common'], {wait: true})
class HourReportingView extends PureComponent<any, any> {

  constructor(props) {
    super(props);

    const {projects, userProjects} = props;

    this.state = {
      projects: this.mergeProjectsWithUserProjects(projects, userProjects)
    };
  }

  componentDidMount() {

    const {getUserProjects, getProjects, authenticatedUser, getTasks, updateCriteria, criteria, location} = this.props;

    getUserProjects(authenticatedUser.id);
    getProjects();
    const taskCriteria = new Criteria({showDisabled: true});
    getTasks(taskCriteria.getQueryParams());
    updateCriteria(location, criteria);
  }

  componentDidUpdate(prevProps) {

    const {
      userProjects,
      projects,
      hourReport,
      hourReports,
      getExpectedHoursForMonth,
      getExpectedHoursForWeek,
      getExpectedHoursFromStartMonthToNow,
      getExpectedHoursFromStartWeekToNow
    } = this.props;

    if (prevProps.userProjects !== userProjects || prevProps.projects !== projects) {

      this.setState({projects: this.mergeProjectsWithUserProjects(projects, userProjects)});
    }

    const hourReportsLoaded = prevProps.hourReports.isLoading && !hourReports.isLoading;
    const hourReportSaved = prevProps.hourReport.isSaving && !hourReport.isSaving;
    const hourReportLoaded = prevProps.hourReport.isLoading && !hourReport.isLoading;

    // Load expected hours after hour reports are loaded / saved
    if (hourReportsLoaded || hourReportSaved || hourReportLoaded) {

      const {start, end} = this.getRange();

      getExpectedHoursForWeek(start);
      getExpectedHoursForMonth(start);
      getExpectedHoursFromStartMonthToNow(start);
      getExpectedHoursFromStartWeekToNow(start);

      // If week presents two different months then load data for both
      if (start.year() < end.year() || start.month() < end.month()) {
        getExpectedHoursForMonth(end);
        getExpectedHoursFromStartMonthToNow(end);
      }
    }
  }

  /**
   * Merge projects with user projects that are not available for user in disabled state to allow presenting
   * hour reporting rows after user has projects removed from users projects.
   * @param projects All projects
   * @param userProjects Users projects
   *
   * @return Merged projects and user projects collection with other than user projects marked as disabled
   */
  mergeProjectsWithUserProjects(projects, userProjects) {

    const userProjectIds = userProjects.list.map(p => p.id).toArray();
    const mergedList = userProjects.list.concat(
      projects.list
        .filter(p => !userProjectIds.includes(p.id))
        .map(p => p.set('disabled', true))
    );

    return userProjects.setList(mergedList);
  }

  getSelectedWeekDays(range) {

    const {projects} = this.state;
    const rangeDays = getDaysForRange(range);
    const {hourReports, tasks, saveModel, deleteModel, t} = this.props;

    return rangeDays.map(date => {

      const reports = hourReports.getHourReportsForDate(date);

      // Include the loading in the key to force new components to allow of setting initial new rows
      // with existing previous rows as source
      return <HourReportingDay key={date + hourReports.isLoading}
                               date={date}
                               saveModel={saveModel}
                               deleteModel={deleteModel}
                               projects={projects}
                               tasks={tasks}
                               reports={reports}
                               t={t}/>;
    });
  }

  onCriteriaChange = (criteria) => this.props.updateCriteria(this.props.location, criteria);

  onWeekSelected = ({start, end}) => this.onCriteriaChange(this.props.criteria.set('from', start).set('to', end));

  getRange = () => {

    const {from, to} = this.props.criteria;

    return moment.range(from, to);
  };

  onCalendarButtonClicked = (type: CalendarButton) => {

    const {start} = this.getRange();
    const range = createWeekRange(
      type === CalendarButton.NEXT_WEEK
        ? start.clone().add(1, 'weeks')
        : start.clone().subtract(1, 'weeks'));

    this.onWeekSelected(range);
  };

  renderReportCalendar = () => {

    const range = this.getRange();
    const {t} = this.props;

    return (
      <div className='hour-reporting-calendar'>
        <button onClick={() => this.onCalendarButtonClicked(CalendarButton.PREVIOUS_WEEK)}
                className='btn btn-default hour-reporting-calendar__button'>
          {t('hourReporting.previousWeek')}
        </button>
        <div className='hour-reporting-calendar__picker'>
          <WeekPicker onWeekSelected={this.onWeekSelected}
                      selectedWeek={range}
          />
        </div>
        <button onClick={() => this.onCalendarButtonClicked(CalendarButton.NEXT_WEEK)}
                className='btn btn-default hour-reporting-calendar__button'>
          {t('hourReporting.nextWeek')}
        </button>
      </div>
    );
  };

  renderWeekDetail = () => {

    const {expectedHours, hourReports} = this.props;
    const range = this.getRange();
    const totalHours = hourReports.getTotalHoursForRange(range);

    return (
      <WeekDetails selectedWeek={range}
                   expectedHours={expectedHours}
                   totalHours={totalHours}/>
    );
  };

  renderRange = () => {

    const {start, end} = this.getRange();

    return (
      <div className='detail'>
        <div className='detail-label'>
          <I18n i18nKey='hourReporting.weekDetailsRange' args={{week: start.isoWeek()}}/>
        </div>
        <div className='detail-value'>
          <span>{start.format(DATE_SHORT_FORMAT)}</span>
          <span className='range-divider'>-</span>
          <span>{end.format(DATE_SHORT_FORMAT)}</span>
        </div>
      </div>
    );
  };

  renderHourReportHeader = () => {

    return (
      <>
        <div className='hour-reporting-header__title'>
          <I18n i18nKey='hourReporting.title'/>
        </div>
        <div className='hour-reporting-header__week-range'>
          {this.renderRange()}
        </div>
        <div className='hour-reporting-header__calendar'>
          {this.renderReportCalendar()}
        </div>
        <div className='hour-reporting-header__expected-hour'>
          {this.renderWeekDetail()}
        </div>
      </>
    );
  };

  render() {

    const range = this.getRange();

    return (
      <div className='hour-reporting-container'>
        <div className='hour-reporting-header'>
          {this.renderHourReportHeader()}
        </div>
        <div className='hour-reporting-content'>
          {this.getSelectedWeekDays(range)}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(HourReportingView);
