import * as React from 'react';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {translate} from 'react-i18next';

@translate(['common'], {wait: true})
export default class Error extends Component<any, any> {

  static propTypes = {
    id: PropTypes.string
  };

  render() {

    const {t, id} = this.props;
    const title = t('error');
    const message = t('errors.' + id);

    return <div className='errors-view-container'>
      <div className='container-fluid'>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <h1>{title}</h1>
            <p className='error-message' data-error-key={this.props.errorId}>{message}</p>
          </div>
        </div>
      </div>
    </div>;
  }
}
