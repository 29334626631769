import HourReports from '../models/HourReports';
import ModelsModule from './ModelsModule';
import HourReport from './HourReport';

const initialState = new HourReports();

class HourReportsModule extends ModelsModule {

  constructor() {
    super('hour-report', initialState);
  }

  protected getAdditionalActionHandlers() {

    return {
      [HourReport.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [HourReport.getUpdateModelRequestType()]: (state, {payload}) => state.addModel(payload.startOfSaving()),
      [HourReport.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [HourReport.getUpdateModelFailedType()]: (state, {payload: {error, model}}) => {

        if (model) {
          return state.addModel(model.endOfSaving(error));
        }

        return state;
      },
      [HourReport.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new HourReportsModule();
