/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import * as React from 'react';
import {Component, PureComponent} from 'react';
import {getFieldError} from '../../components/ErrorsMixin';
import I18n from '../../components/I18n';
import Input from '../../components/Input';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import User from '../../modules/User';
import Permission from '../../services/Permission';
import ProjectSelect from '../../components/ProjectSelect';
import Select from '../../components/Select';
import {UserRole} from '../../models/User';
import HourInput from '../../components/HourInput';
import Config from '../../config';

class UserDetails extends Component<any, any> {

  static propTypes = {
    userId: PropTypes.number.isRequired,
    model: PropTypes.object.isRequired,
    saveModel: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isAdmin: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
  }

  onFieldChange = (field, value) => this.props.saveModel(this.props.model.set(field, value));

  onProjectsChange = (selectedIds) => {

    const {saveModel, model} = this.props;

    saveModel(model.set('projectIds', model.projectIds.clear().concat(selectedIds)));
  };

  onRoleChange = (selectedId) => {

    const {saveModel, model} = this.props;

    saveModel(model.set('role', selectedId));
  };

  emails = () => {

    return this.props.model.emails.map((email) => {

      return (
        <p key={'email-' + email.email} className='form-control-static'>
          <a
            href={'mailto:' + email.email}>
            {email.email}
          </a>
        </p>
      );
    });
  };

  role = () => {

    const {isAdmin, model} = this.props;
    const options = this.getUserRoleOptions();

    return (
      <div key='user_role_group' className='form-group'>
        <label className='col-sm-2 control-label'><I18n i18nKey='role'/></label>

        <div className='col-sm-10'>
          <Select key='user_projects'
                  onChange={this.onRoleChange}
                  options={options}
                  selectedOptions={model.role}
                  isDisabled={!isAdmin}
          />
        </div>
      </div>
    );
  };

  getUserRoleOptions = () => {

    const {t} = this.props;

    return Object.keys(UserRole).map(role => ({value: UserRole[role], label: t(`userRole.${UserRole[role]}`)}));
  };

  phone = () => {

    const {model, t, readOnly} = this.props;
    const fieldError = getFieldError('phone', model.validate(), model.error);

    return (
      <Input key='phone'
             label={<I18n i18nKey='phone'/>}
             labelClassName='col-xs-2'
             error={fieldError}
             onChange={event => this.onFieldChange('phone', event.target.value)}
             value={model.phone}
             wrapperClassName='col-sm-10'
             placeholder={t('placeholder.phone')}
             disabled={readOnly}/>
    );
  };

  hours = () => {

    const {model, t, isAdmin} = this.props;
    const fieldError = getFieldError('expectedHours', model.validate(), model.error);
    const readOnly = !isAdmin; // Only allow editing of hours by managers and the like
    const value = (!readOnly || model.expectedHours) ? model.expectedHours : Config.EXPECTED_HOURS;

    return (
      <HourInput key='expectedHours'
                 label={<I18n i18nKey='expectedHours'/>}
                 labelClassName='col-xs-2'
                 error={fieldError}
                 onChange={value => this.onFieldChange('expectedHours', value)}
                 value={value}
                 wrapperClassName='col-sm-10'
                 placeholder={t('placeholder.expectedHours')}
                 disabled={readOnly}/>
    );
  };

  render() {

    const {model, readOnly} = this.props;

    return (
      <form className='form-horizontal'>
        <div key='display_name_group' className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='name'/></label>

          <div className='col-sm-10'>
            <p className='form-control-static'>{model.displayName}</p>
          </div>
        </div>
        {this.role()}
        {this.phone()}
        {this.hours()}
        <div key='user_projects_group' className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='projects'/></label>

          <div className='col-sm-10'>
            <ProjectSelect key='user_projects'
                           selectedOptions={model.projectIds.toArray()}
                           onChange={this.onProjectsChange}
                           readOnly={readOnly}/>
          </div>
        </div>
        <div key='email_group' className='form-group'>
          <label className='col-sm-2 control-label'><I18n i18nKey='email'/></label>

          <div className='col-sm-10'>
            {this.emails()}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({user, authenticatedUser}, ownProps) => {

  const userId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : ownProps.userId;
  const isAdmin = authenticatedUser.hasPermission(Permission.WriteUser);
  const readOnly = user.id === authenticatedUser.id
    ? !authenticatedUser.hasPermission(Permission.WriteUserProfile)
    : !isAdmin;

  return {
    userId,
    model: user,
    readOnly,
    isAdmin
  };
};

const mapActionsToProps = {
  getModel: User.getModel,
  saveModel: User.saveModelDebounced
};

@translate(['common'], {wait: true})
class UserView extends PureComponent<any, any> {

  static propTypes = {
    userId: PropTypes.number.isRequired,
    model: PropTypes.object.isRequired,
    getModel: PropTypes.func.isRequired,
    saveModel: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isAdmin: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    const {getModel, userId} = this.props;
    getModel(userId);
  }

  title = () => {

    const {model} = this.props;

    if (!model) return <div/>;

    return <I18n i18nKey='userView.title' args={{name: model.displayName || ''}}/>;
  };

  userDetails = () => {

    const {model, t, saveModel, userId, readOnly, isAdmin} = this.props;

    if (!model) return <div/>;

    return <UserDetails key='user_details'
                        userId={userId}
                        model={model}
                        t={t}
                        isAdmin={isAdmin}
                        saveModel={saveModel}
                        readOnly={readOnly}/>;
  };

  render() {

    return (
      <div key='user-view-container' className='container'>
        <div key='title' className='page-title'>
          {this.title()}
        </div>
        {this.userDetails()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UserView);
