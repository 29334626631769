import * as React from 'react';
import {PureComponent} from 'react';
import HourReportingDayRow from './HourReportingDayRow';
import {PropTypes} from 'prop-types';
import {DATE_LONG_FORMAT} from '../config/constants';
import HourReport from '../models/HourReport';
import * as Moment from 'moment';
import {isSameDay, checkDateIsHoliday} from '../util';

export default class HourReportingDay extends PureComponent<any, any> {

  static propTypes = {
    saveModel: PropTypes.func.isRequired,
    deleteModel: PropTypes.func.isRequired,
    projects: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      model: this.createEmptyModel(props.reports.last())
    };
  }

  componentDidUpdate(prevProps) {

    const {reports, projects} = this.props;

    if (reports !== prevProps.reports) {

      const model = reports.find(r => r._id === this.state.model._id);

      // New model is persisted, create a new empty row.
      if (model && model.id) {

        const project = projects.getModelById(model.projectId);
        this.setState({model: this.createEmptyModel(project && project.disabled ? undefined : model)});
      }

      const prevModel = prevProps.reports.find(r => r._id === this.state.model._id);

      // If report changes and is same as model then update it but only if not persisted (has id).
      if (prevModel !== model && !model.id && model._id === this.state.model._id) {

        this.setState({model});
      }
    }

    const {model} = this.state;

    // Check and create new empty row model after projects have loaded if previous row was a disabled project.
    if (!projects.isLoading && projects !== prevProps.projects && model) {

      const project = projects.getModelById(model.projectId);

      if (!project || project.disabled) {

        this.setState({model: this.createEmptyModel()});
      }
    }
  }

  createEmptyModel = (model?) => {

    return new HourReport({
      date: this.props.date.format('YYYY-MM-DD'),
      projectId: model ? model.projectId : undefined,
      taskId: model ? model.taskId : undefined
    });
  };

  getReportingDayRows = () => {

    const {reports, projects, tasks, saveModel, deleteModel, t} = this.props;

    let list = reports;

    const model = this.state.model;

    // Add the empty row model if not already in the list
    if (!reports.find(r => r._id === model._id)) {

      list = list.push(model);
    }

    return list.map((report) => <HourReportingDayRow key={report._id}
                                                     tasks={tasks}
                                                     projects={projects}
                                                     report={report}
                                                     deleteModel={deleteModel}
                                                     onChange={saveModel}
                                                     t={t}/>
    ).toArray();
  };

  getReportingDayTitle = () => {

    const {date} = this.props;

    const dateHoliday = checkDateIsHoliday(date);

    if (dateHoliday) {

      return `${date.format(DATE_LONG_FORMAT)} (${dateHoliday.name})`;
    }

    return date.format(DATE_LONG_FORMAT);
  };

  getReportingDayClassName = () => {

    const {date} = this.props;

    const today = Moment();
    const saturday = date.clone().weekday(6);
    const sunday = date.clone().weekday(0);

    let classes = ['reporting-day'];

    if (isSameDay(date, today)) {

      classes.push('reporting-day--today');
    }

    if (isSameDay(date, saturday) || isSameDay(date, sunday)) {
      classes.push('reporting-day--weekend');
    }

    if (!!checkDateIsHoliday(date)) {
      classes.push('reporting-day--holiday');
    }

    return classes.join(' ');
  }

  render() {

    const reportingDayTitle = this.getReportingDayTitle();

    return (
      <div className={this.getReportingDayClassName()}>
        <div key={'title-' + reportingDayTitle} className='reporting-day-title'>{reportingDayTitle}</div>
        {this.getReportingDayRows()}
      </div>
    );
  }
}
