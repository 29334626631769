import * as React from 'react';
import Toggle from 'react-toggle';

interface Props {
  label: string;
  onChange: any;
  checked: boolean;
}

const toggleButton = (props: Props) => {

  return (
    <div className='toggle-button'>
      <span className='toggle-button__label'>{props.label}</span>
      <Toggle
        checked={props.checked}
        onChange={props.onChange} />
    </div>
  );
};

export default toggleButton;
