/**
 * Created by Mauritz Untamala on 02/10/15.
 */
import * as React from 'react';
import {Component} from 'react';
import * as ReactTooltip from 'react-tooltip';

export default class ToolTip extends Component<any, any> {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showToolTip: false
    };
  }

  componentDidMount() {

    this.setState({showToolTip: true});
  }

  render() {

    if (this.state.showToolTip) {

      return <ReactTooltip/>;

    } else {

      return <div/>;
    }
  }
}
