/**
 * Created by Mauritz Untamala on 01/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import DatePicker from 'react-date-picker';
import * as Moment from 'moment';
import {PropTypes} from 'prop-types';
import {extendMoment} from 'moment-range';
import {createWeekRange} from '../util';

const moment = extendMoment(Moment);

export default class WeekPicker extends Component<any, any> {

  static propTypes = {
    onWeekSelected: PropTypes.func.isRequired,
    selectedWeek: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date()
    };
  }

  componentDidUpdate(prevProps) {

    const {selectedWeek} = this.props;
    const containedSelectedDate = selectedWeek.contains(this.state.date);

    if (prevProps.selectedWeek !== selectedWeek && !containedSelectedDate) {
       this.setState({
         date: selectedWeek.start.toDate()
       });
    }
  }

  onChange = (date) => {

    // Set current date as selected when date is cleared
    if (!date) {
      date = new Date();
    }

    this.setState({date}, () => this.props.onWeekSelected(createWeekRange(moment(date))));
  };

  onRenderTile = ({date, view}) => view === 'month' && this.props.selectedWeek.contains(date) ? 'selectedWeekDay' : null;

  render() {

    return (
      <DatePicker calendarType='ISO 8601'
                  locale='en-GB'
                  value={this.state.date}
                  onChange={this.onChange}
                  showWeekNumbers={true}
                  tileClassName={this.onRenderTile}/>
    );
  }
}
