import Tasks from '../models/Tasks';
import ModelsModule from './ModelsModule';
import Task from './Task';
import {bind} from '../util';

const initialState = new Tasks();

class TasksModule extends ModelsModule {

  constructor() {
    super('task', initialState);
    bind(this, this.fetchProjectTasks, this.fetchUserTasks);
  }

  fetchProjectTasks(projectId) {

    if (projectId) {

      return this.getModels(undefined, true, 'project', `${projectId}/tasks`);

    } else {

      return this.getModels(undefined, true);
    }
  }

  fetchUserTasks(userId) {

    return this.getModels(undefined, true, 'user', `${userId}/tasks`);
  }

  protected getAdditionalActionHandlers() {

    return {
      [Task.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Task.getUpdateModelRequestType()]: (state, {payload}) => state.addModel(payload.startOfSaving()),
      [Task.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Task.getUpdateModelFailedType()]: (state, {payload: {error, model}}) => {

        if (model) {
          return state.addModel(model.endOfSaving(error));
        }

        return state;
      },
      [Task.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new TasksModule();
