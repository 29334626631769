import Config from '../config';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import moment from 'moment-es6';
import {extendMoment} from 'moment-range';
import {Moment} from 'moment';

const Holidays = require('date-holidays');

const momentRange = extendMoment(moment);

enum HolidayType {
  PUBLIC = 'public',
  BANK = 'bank'
}

const opts = {
  languages: 'en',
  types: [HolidayType.PUBLIC]
};

const holidays = new Holidays('FI', opts);

export function generateUUID() {
  return uuid.v4();
}

export function formatDate(date, dateOnly = false) {

  if (dateOnly) {

    return moment(date).format('D.M.YYYY');

  } else {

    return moment(date).format('D.M.YYYY HH:mm');
  }
}

export function formatHour(value) {

  if (!isNull(value)) {

    let matches = value.match(hourRegex);

    if (matches) {

      let formatted = matches[1] ? parseInt(matches[1], 10) : '';
      formatted = formatted + (matches[2] ? matches[2] : '');

      return `${formatted}${matches[3] ? parseInt(matches[3], 10) : ''}`;
    }
  }

  return value;
}

export function bindOn(obj) {
  Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
    .filter(name => name.indexOf('on') === 0)
    .forEach(name => {
      if (obj[name]) {
        obj[name] = obj[name].bind(obj);
      }
    });
}

export function bind(obj, ...methods) {
  methods.forEach(method => {

    if (method.name !== '' && !!obj[method.name]) {

      obj[method.name] = obj[method.name].bind(obj);

    } else {

      const name: any = Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
        .find(propertyName => obj[propertyName] === method);
      if (obj[name]) {
        obj[name] = obj[name].bind(obj);
      }
    }
  });
}

export function bindAll(obj) {

  Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).forEach(property => {

    if (typeof obj[property] === 'function' && property !== 'constructor') {

      obj[property] = obj[property].bind(obj);
    }
  });
}

export const logDebug = (...parts) => {

  if (Config.env.NODE_ENV === 'development') {
    console.log(parts);
  }
};

export function getFieldError(field, validationErrors, modelError, value?) {

  const fieldErrorsV2 = modelError && modelError.fieldErrorsV2;
  const fieldErrorV2 = fieldErrorsV2 ? fieldErrorsV2.find(fe => fe.field === field && (value ? fe.value === value : true)) : undefined;

  if (fieldErrorV2) {
    return fieldErrorV2;
  }

  const getFieldError = () => {

    const fieldErrors = modelError && modelError.fieldErrors;
    const object = modelError && modelError.object;

    if (fieldErrors && fieldErrors[field] && (!value || (value && object && object[field] === value))) {

      return modelError.fieldErrors[field];

    } else if (validationErrors && validationErrors[field]) {

      return validationErrors[field];
    }
  };

  const fieldError = getFieldError();

  return _.isArray(fieldError) ? fieldError.join(' ') : fieldError;
}

export function toIntArray(array) {

  if (_.isEmpty(array)) {

    return [];
  }

  if (!_.isArray(array)) {

    array = [array];
  }

  return array.map(value => parseInt(value, 10));
}

export function stringToIntArray(values) {

  if (_.isEmpty(values)) {

    return [];
  }

  if (_.isArray(values)) {

    return toIntArray(values);

  } else {

    values = values.split(',');

    return toIntArray(values);
  }
}

export const hourRegex = /^(\d{0,2})(?:(\.)([05])?)?$/;

export function isNull(variable) {
  return (typeof variable === 'undefined' || variable === null);
}

export function isEqual(interestedKeys, object, otherObject) {

  return _.isMatchWith(object, otherObject, function (value, other, key) {

    if (_.includes(interestedKeys, key)) {

      if (moment.isMoment(value)) {
        return value.isSame(other);
      }

      if (_.isArray(value)) {
        return _.difference(value, other).length === 0;
      }

      value = _.isUndefined(value) ? null : value;
      other = _.isUndefined(other) ? null : other;

      return _.isEqual(value, other);
    }
    return true;
  });
}

export function roundHalf(num) {
  return Math.round(num * 2) / 2;
}

/**
 * Parses mixed type values into booleans. This is the same function as filter_var in PHP using boolean validation
 * @param  {Object}        value
 * @param  {Boolean}      defaultValue = undefined
 * @return {Boolean|defaultValue}
 */
export function parseBooleanStyle(value, defaultValue = undefined) {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      value = true;
      break;
    case false:
    case 'false':
    case 0:
    case '0':
    case 'off':
    case 'no':
      value = false;
      break;
    default:
      value = defaultValue;
      break;
  }
  return value;
}

export function getDaysForRange(range) {
  let days = [];

  Array
    .from(range.by('days'))
    .forEach(function (moment) {
      days.push(moment);
    });

  return days;
}

export function createMonthRange(month: Moment) {

  return momentRange.range(
    month.clone().startOf('month'),
    month.clone().endOf('month')
  );
}

export function createWeekRange(month: Moment) {

  return momentRange.range(
    month.clone().startOf('isoWeek'),
    month.clone().endOf('isoWeek')
  );
}

export function createFromDateToNowRange(date: Moment, starter: 'week'|'month') {

  return momentRange.range(
    date.clone().startOf(starter),
    moment().endOf('day')
  );
}

export function isSameDay(date: Moment, otherDate: Moment) {

  return date.isSame(otherDate, 'day');
}

export function checkDateIsHoliday(date: Moment) {

  return holidays.isHoliday(date.toDate());
}
