import Projects from '../models/Projects';
import ModelsModule from './ModelsModule';
import Project from './Project';

const initialState = new Projects();

class ProjectsModule extends ModelsModule {

  constructor() {
    super('project', initialState);
  }

  protected getAdditionalActionHandlers() {

    return {
      [Project.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload, true),
      [Project.getUpdateModelRequestType()]: (state, {payload}) => state.addModel(payload.startOfSaving()),
      [Project.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Project.getUpdateModelFailedType()]: (state, {payload: {error, model}}) => {

        if (model) {
          return state.addModel(model.endOfSaving(error));
        }

        return state;
      },
      [Project.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new ProjectsModule();
