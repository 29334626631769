import * as React from 'react';
import {connect} from 'react-redux';
import AuthenticatedUser from '../../../modules/AuthenticatedUser';
import GoogleLogin from 'react-google-login';
import {logDebug} from '../../../util';
import {translate} from 'react-i18next';
import Config from '../../../config';

const logo = require('../../../images/make-logo.svg');

const mapActionCreators = {
  login: AuthenticatedUser.login
};

const mapStateToProps = (state, ownProps) => ({
  user: state.authenticatedUser,
  redirect: ownProps.location.query.redirect
});

interface Props {
  user: any;
  login;
  redirect;
  t;
}

@translate(['common'], {wait: true})
class LoginView extends React.Component<Props, any> {

  onResponseGoogleSuccess = (response) => this.props.login(response.tokenId, this.props.redirect);

  onResponseGoogleFailure = (response) => logDebug('Google failure response:', response);

  render() {

    const {t} = this.props;

    return (
      <div className='form-signin'>
        <img src={logo} alt='Make Helsinki'/>
        <GoogleLogin
          clientId={Config.GOOGLE_CLIENT_ID}
          scope={Config.GOOGLE_LOGIN_SCOPE}
          buttonText={t('button.login')}
          className='btn btn-default'
          onSuccess={this.onResponseGoogleSuccess}
          onFailure={this.onResponseGoogleFailure}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionCreators)(LoginView);
