import Projects from '../models/Projects';
import ModelsModule from './ModelsModule';
import {bind} from '../util/index';
import Project from './Project';

const initialState = new Projects();

class CustomerProjectsModule extends ModelsModule {

  constructor() {
    super('customer-project', initialState, {path: 'customer'});
    bind(this, this.fetchCustomerProjects);
  }

  fetchCustomerProjects(customerId) {

    return this.getModels(undefined, true, undefined, `${customerId}/projects`);
  }

  protected getAdditionalActionHandlers() {

    return {
      [Project.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Project.getUpdateModelRequestType()]: (state, {payload}) => state.addModel(payload.startOfSaving()),
      [Project.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Project.getUpdateModelFailedType()]: (state, {payload: {error, model}}) => {

        if (model) {
          return state.addModel(model.endOfSaving(error));
        }

        return state;
      },
      [Project.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new CustomerProjectsModule();
