/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import {getFieldError} from './ErrorsMixin';
import Input from './Input';
import {PropTypes} from 'prop-types';
import {translate} from 'react-i18next';

@translate(['common'], {wait: true})
export default class TextColumn extends Component<any, any> {

  static propTypes = {
    t: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    rowData: PropTypes.object.isRequired,
    data: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onEditingDone: PropTypes.func,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string
  };

  private input;

  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: !this.props.rowData.id,
      showError: this.props.rowData.id
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (!prevState.editing && this.state.editing) {

      if (this.input) this.input.focus();
      if (this.props.onEditingStart) this.props.onEditingStart();

    } else if (prevState.editing && !this.state.editing) {

      if (this.props.onEditingDone) this.props.onEditingDone();
    }

    // Enable editing if model sets editing to true
    if (!this.state.editing && !prevProps.rowData.editing && this.props.rowData.editing) {

      this.onSetEditing();
    }
  }

  onBlur = () => this.onSetNotEditing();

  onChange = (event) => this.setState({showError: true}, () => this.props.onChange(event.target.value));

  onFocus = () => this.onSetEditing();

  onKeyDown = (event) => {

    if (event.which === 13) {
      this.onSetNotEditing();
    }
  };

  getContent = () => {

    if (this.state.editing) {

      return this.getEditField();

    } else {

      const {field, rowData, t, data} = this.props;

      return (
        <div key={field + '_readonly_' + rowData.src._id}
             onClick={this.onSetEditing}
             data-tip={t('tooltip.clickToEdit')}>
          {data}
        </div>
      );
    }
  };

  getEditField = () => {

    const {field, rowData, t, data, placeholder} = this.props;
    const fieldError = this.state.showError ? this.getInputFieldError() : null;

    return (
      <Input key={field + '_' + rowData.src._id}
             ref={input => this.input = input}
             error={fieldError}
             onKeyDown={this.onKeyDown}
             onBlur={this.onBlur}
             onChange={this.onChange}
             onFocus={this.onFocus}
             value={data}
             placeholder={placeholder || t('placeholder.' + field)}/>
    );
  };

  getInputFieldError = () => {

    const {rowData, field, data} = this.props;
    const errors = rowData.src.set(field, data).validate();

    return getFieldError(field, errors, rowData.src.error);
  };

  onSetEditing = () => !this.props.readOnly && this.setState({editing: true});

  onSetNotEditing = () => {

    const obj = {showError: true} as any;

    if (!this.getInputFieldError()) {
      obj.editing = false;
    }

    this.setState(obj);
  };

  render() {

    return this.getContent();
  }
}
