import HourReport from '../models/HourReport';
import ModelModule from './ModelModule';

const initialState = new HourReport();

export class HourReportModule extends ModelModule<HourReport> {

  constructor() {
    super('hour-report', initialState);
  }
}

export default new HourReportModule();
