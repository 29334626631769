import * as React from 'react';
import {connect} from 'react-redux';
import {UserIsAuthenticated} from '../../config/policies';
import {push} from 'react-router-redux';
import * as PropTypes from 'prop-types';
import '../../styles/styles.less';
import ToolTip from '../../components/ToolTip';

const mapActionCreators = {push};

const mapStateToProps = (_state, {location: {query: {redirect}}}) => ({redirectUrl: redirect});

interface Props {
  location: any;
  redirectUrl: string;
  push: any;
}

@UserIsAuthenticated
class AppLayout extends React.Component<Props, any> {

  static propTypes = {
    children: PropTypes.element.isRequired,
    redirectUrl: PropTypes.string,
    push: PropTypes.func,
    location: PropTypes.any.isRequired
  };

  componentDidMount() {

    const {redirectUrl, push} = this.props;

    if (redirectUrl) {
      push(redirectUrl);
    }
  }

  render() {

    return (
      <>
        {this.props.children}
        <ToolTip/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapActionCreators)(AppLayout);
