/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import Users from '../../modules/Users';
import User from '../../modules/User';
import {translate} from 'react-i18next';
import {navigate} from '../../modules/Location';
import BaseTableView from '../../components/BaseTableView';
import DisabledColumn from '../../components/DisabledColumn';
import StatusColumn from '../../components/StatusColumn';
import Permission from '../../services/Permission';

class UserLink extends Component<any, any> {

  constructor(props) {
    super(props);
  }

  navigateToUser = (event) => {

    event.preventDefault();
    this.props.navigate('/user/' + this.props.rowData.id);
  };

  render() {

    return (
      <a href={'/user/' + this.props.rowData.id}
         onClick={this.navigateToUser}
         role='button'>
        {this.props.data}
      </a>
    );
  }
}

const mapStateToProps = ({users, authenticatedUser}, _ownProps) => {

  const readOnly = !authenticatedUser.hasPermission(Permission.WriteUser);

  return {
    models: users,
    readOnly,
    initialSortColumn: 'givenName',
    initialSortState: 'asc'
  };
};

const mapActionsToProps = {
  getModels: Users.getModels,
  saveModel: User.saveModel,
  navigate
};

@translate(['common'], {wait: true})
class UsersView extends BaseTableView {

  constructor(props) {
    super(props, 'users', true);
  }

  getColumnMetadata() {

    const {t, readOnly} = this.props;

    const columns = [
      {
        'columnName': 'givenName',
        'displayName': t('givenName'),
        'customComponent': UserLink
      },
      {
        'columnName': 'familyName',
        'displayName': t('familyName'),
        'customComponent': UserLink
      },
      {
        'columnName': 'phone',
        'displayName': t('phone'),
        'customComponent': UserLink
      },
      {
        'columnName': 'role',
        'displayName': t('role'),
        'customComponent': UserLink
      },
      {
        'columnName': 'disabled',
        'cssClassName': 'disabled-column',
        'displayName': t('column.disabled'),
        'customComponent': DisabledColumn,
        'filterValue': data => t('' + !data),
        'sortValue': data => t('' + !data)
      }
    ] as Array<any>;

    if (!readOnly) {

      columns.push({
        'columnName': 'isSaving',
        'displayName': '',
        'cssClassName': 'status-column',
        'customComponent': StatusColumn,
        'sortable': false
      });
    }

    return columns;
  }

  createEmptyModel() {

    return null;
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UsersView);
