enum Permission {
  ReadCustomer = 'read:customer',
  WriteCustomer = 'write:customer',
  ReadProject = 'read:project',
  WriteProject = 'write:project',
  ReadTask = 'read:task',
  WriteTask = 'write:task',
  ReadUser = 'read:user',
  WriteUser = 'write:user',
  ReadUserProfile = 'read:user-profile',
  WriteUserProfile = 'write:user-profile',
  ReadHourReport = 'read:hour-report',
  WriteHourReport = 'write:hour-report',
  ReadReport = 'read:report',
  WriteReport = 'write:report'
}

export default Permission;
