import BaseModel from './BaseModel';

const defaultValues = {
  name: undefined,
  disabled: undefined,
  default: undefined
};

const equalsKeys = ['name', 'disabled', 'default'];

const constraints = {
  name: {
    presence: true
  }
};

export default class Task extends BaseModel(defaultValues, equalsKeys, constraints)<Task> {

  name: string;
  disabled: boolean;
  default: boolean;

  constructor(js?) {
    super(js);
  }

  fromJS(js): Task {

    return new Task(js);
  }
}
