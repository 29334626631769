/**
 * Created by Mauritz Untamala on 20/07/18.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import * as _ from 'lodash';
import I18n from './I18n';
import TableComponent from './TableComponent';
import * as PropTypes from 'prop-types';

export default abstract class BaseTableView extends PureComponent<any, any> {

  static propTypes = {
    updateCriteria: PropTypes.func,
    location: PropTypes.object,
    criteria: PropTypes.object,
    getModels: PropTypes.func.isRequired,
    models: PropTypes.object.isRequired,
    model: PropTypes.object,
    navigate: PropTypes.func
  };

  readonly contextName;
  readonly skipEmptyRow;

  constructor(props, contextName, skipEmptyRow = false) {
    super(props);
    this.contextName = contextName;
    this.skipEmptyRow = skipEmptyRow || props.readOnly;

    if (!this.skipEmptyRow) {
      this.state = {
        model: this.createEmptyModel()
      };
    }
  }

  componentDidMount() {

    const {updateCriteria, criteria, location} = this.props;

    if (updateCriteria && criteria && location) {

      updateCriteria(location, criteria);

    } else {

      this.getModels();
    }
  }

  protected getModels() {
    this.props.getModels();
  }

  abstract createEmptyModel();

  componentDidUpdate(prevProps) {

    const {model, models, criteria, updateCriteria, location} = this.props;

    if (criteria && !prevProps.criteria.isEqual(criteria) && !models.isLoading) {

      updateCriteria(location, criteria);
    }

    if (!this.skipEmptyRow) {

      if (prevProps.models !== models) {

        const model = models.getModelById(this.state.model._id);

        // New model is persisted, create a new empty row.
        if (model && model.id) {
          this.setState({model: this.createEmptyModel()});
        }
      }

      /*
      If model changes and is same as model then update it but only if not persisted (has id).
      Set editing if error to display errors on components.
      */
      if (prevProps.model !== model && !model.id && model._id === this.state.model._id) {
        this.setState({model: model.set('editing', model.editing || !!model.error)});
      }
    }
  }

  abstract getColumnMetadata();

  protected getTopContent() {
    return null;
  }

  onCriteriaChange = (criteria) => {

    const {updateCriteria, location} = this.props;

    if (updateCriteria && location) {
      return updateCriteria(location, criteria);
    }
  };

  getResults() {

    let list = this.props.models.list.map((model) => _.extend(model.toJS(), {src: model}));

    if (!this.skipEmptyRow && !this.props.models.getModelById(this.state.model._id)) {

      const model = this.state.model;
      list = list.unshift(_.extend(model.toJS(), {src: model}));
    }

    return list.toArray();
  }

  interceptSaveModel = (model) => {

    // Store changes of a new model entry to state until editing ends and model is valid
    if (!model.id && model._id === this.state.model._id && (!model.isValid() || model.editing)) {

      this.setState({model: model.set('error', undefined)});

    } else {

      this.props.saveModel(model);
    }
  };

  getTitle() {

    return <div className='page-title'>
      <I18n i18nKey={`${this.contextName}View.title`}/>
    </div>;
  }

  render() {

    const {saveModel, initialSortColumn, initialSortState, ...rest} = this.props;
    const customProps = _.merge({saveModel: this.interceptSaveModel}, rest);

    return (
      <div className={`${this.contextName}-view-container models-view-container`}>
        {this.getTitle()}
        <TableComponent key='table-component'
                        topContent={this.getTopContent()}
                        data={this.getResults()}
                        showFilter={true}
                        rowKey='_id'
                        columns={this.getColumnMetadata()}
                        useFixedHeader={true}
                        customProps={customProps}
                        initialSortColumn={initialSortColumn}
                        initialSortState={initialSortState}
        />
      </div>
    );
  }
}
