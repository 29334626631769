/**
 * Created by Mauritz Untamala on 01/03/2017.
 */
import {List, Record} from 'immutable';
import * as _ from 'lodash';

class App extends Record({
  initializing: false,
  initialized: false,
  connectivity: true,
  uiActions: List(),
  availableFeatures: List(),
  availableRoles: List(),
  error: undefined
}) {

  availableRoles: List<any>;
  connectivity: boolean;

  public setConnectivity(connectivity) {

    return this.set('connectivity', connectivity);
  }

  public isConnected() {

    return this.connectivity;
  }

  public setAvailableRoles(availableRolesJS) {

    return this.setListArray('availableRoles', availableRolesJS);
  }

  private setListArray(key, arrayJS) {

    return this.set(key, List(_.isArray(arrayJS) ? arrayJS : []));
  }
}

export default App;
